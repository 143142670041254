import React from 'react'

const SecondComponent = () => {
    const containerArrayDetails = [
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/route.mp4",
            mainHeading: "Tractores guiados por GPS",
            paragraphs: "Nuestros tractores equipados con tecnología GPS permiten una navegación precisa y eficiente en el campo, reduciendo la superposición y minimizando el desperdicio de esfuerzo."
        },
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/destination.mp4",
            mainHeading: "Monitoreo y control remotos",
            paragraphs: "Los agricultores pueden aprovechar los teléfonos inteligentes y las tabletas para monitorear de forma remota el rendimiento de sus equipos, ajustar la configuración e incluso solucionar problemas en tiempo real.."
        },
    ]
    return (
        <div className='SecondComponent'>

            <p className='mainHeading'>
            Equipos inteligentes y automatización
            </p>

            <div className='container'>

                {
                    containerArrayDetails.map((details, index) => {
                        return (
                            <div
                                className='box'
                                key={index}>
                                <div className="boxTop">
                                    <video
                                        src={details.videoLink}
                                        autoPlay
                                        loop
                                        controls={false}
                                        width="120px" // Adjust width as needed
                                        height="auto" // Adjust height as needed
                                    />
                                    <div className='boxTopRight'>
                                        <p>
                                            {details.mainHeading}
                                        </p>
                                    </div>
                                </div>
                                <div className="boxBottom">
                                    <p>
                                        {details.paragraphs}
                                    </p>
                                </div>
                            </div>
                        )
                    })
                }

            </div>

        </div>
    )
}

export default SecondComponent