import React from 'react'
import './ServiciosDeSoporteDeAgentes.scss'
import { motion } from 'framer-motion'
import SecondComponent from './SecondComponent'
import ThirdComponent from './ThirdComponent'
import FourthComponent from './FourthComponent'

const ServiciosDeSoporteDeAgentes = () => {
  return (
    <div className='ServiciosDeSoporteDeAgentes'>

      <div className="banner">

        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/serviso/agent-support.webp" alt="banner image" />

        <div className='shadow' />

        <motion.div
          initial={{
            x: -100,
            opacity: 0
          }}
          whileInView={{
            x: 0,
            opacity: 1
          }}
          transition={{
            duration: 1
          }}
          className='bannerTextContainer'>

          <div className='bannerText'>
            <p>
              Servicios de soporte de agentes
            </p>
            <p>
              Los principales servicios de asistencia para agentes de Holatractor, su socio tractor en cada paso del camino.
            </p>
            <button
              name="publicación comunitaria button">
              Conectar un agente
            </button>
          </div>

        </motion.div>

      </div>

      <SecondComponent />

      <ThirdComponent />

      <FourthComponent />

    </div>
  )
}

export default ServiciosDeSoporteDeAgentes