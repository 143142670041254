import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ThirdComponent = () => {

    const [expandedState, setExpandedState] = useState(0)

    const componentArray = [
        {
            tag: "Encontrar el tractor adecuado",
            details: "Nuestros agentes de soporte conocen los diferentes modelos de tractores y accesorios disponibles. Pueden ayudarlo a elegir el equipo adecuado según sus necesidades específicas."
        },
        {
            tag: "Proceso de reserva y alquiler",
            details: "¿Necesita ayuda para navegar por el proceso de reserva en línea? Nuestros agentes estarán encantados de guiarle paso a paso, responder a cualquier pregunta que pueda tener y ayudarle a reservar el alquiler de su tractor."
        },
        {
            tag: "Apoyo técnico.",
            details: "¿Tiene alguna dificultad técnica en la plataforma? Nuestros agentes están disponibles para solucionar problemas y garantizar una experiencia de usuario perfecta."
        },
        {
            tag: "Preguntas frecuentes y consultas generales",
            details: "¿Tiene alguna pregunta sobre tarifas de alquiler, pólizas de seguro u otros aspectos de nuestro servicio? ¡No dudes en comunicarte! Nuestros agentes están aquí para brindarle respuestas y abordar sus inquietudes."
        },
       
    ]

    return (
        <div className="ThirdComponent">

            <div className='left'>

                <img
                    src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/field_agent.webp"
                    alt=""
                    className='w-full 1000px:w-[90%] rounded-[6px]' />

            </div>

            <div className="rigth">

                <div className="heading">¡Cómo nuestros dedicados agentes pueden ayudar a los agricultores a alquilar tractores!</div>

                {
                    componentArray.map((details, index) => {
                        return(
                            <div key={index}>

                                <div className='box-top' onClick={()=>{ setExpandedState(index) }}>
                                    <span>{details.tag}</span>
                                <ExpandMoreIcon
                                style={{
                                    transform: expandedState === index ? 'rotate(180deg)' : 'rotate(0deg)'
                                }} />
                                </div>

                                <div className='box-bottom'
                                style={{
                                    height: expandedState === index ? '100px' : '0px',
                                    display: expandedState === index ? 'block' : 'none'
                                }}>
                                {details.details}
                                </div>

                            </div>
                        )
                    })
                }

            </div>

        </div>
    )
}

export default ThirdComponent