import React from 'react'
import './ServicesTractores.scss'
import serviceBackgroundVideo from '../../../assets/video/farmer_tractor_assembly_service.mp4'
import MiddleBtn from '../../../components/Home/HomeMiddle/MiddleButton/MiddleBtn'
import MiddleSection from './MiddleSection'
import FooterSection from './FooterSection'

const ServicesTractores = () => {
    return (
        <div className='ServicesTractores'>
{/* 
            <div className="video-section">

                <video autoPlay loop muted>
                    <source src={serviceBackgroundVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>

                <p>
                    Transforming businesses through technology.
                </p>

                <div className='button'>
                    <MiddleBtn />
                </div>

            </div> */}

            {/* <div className="below-video-section">

                <img src="https://wallpapercave.com/wp/wp9064389.jpg" alt="" />

                <div className="heading-one">
                    The Uber you know, reimagined for business
                </div>

                <div className="heading-two">
                    A platform for managing global rides and meals, and local deliveries, for companies of any size.
                </div>

                <div className="button">Get started</div>

            </div> */}

            {/* <MiddleSection /> */}

            {/* <FooterSection /> */}

        </div>
    )
}

export default ServicesTractores