import React, { useState } from "react";
import "./login.scss";
import IndiaImg from "../../images/india.png";
import { useDispatch, useSelector } from "react-redux";
import { updateUserComponent } from "../../redux/userLogInComponentShow";
import { auth } from "../../firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { Link } from "react-router-dom";

const Login = () => {

  const dispatch = useDispatch()
  const userComponent = useSelector(state => state.userLogInComponentShow)

  const [phoneNumber, setPhoneNumber] = useState()
  const [firebaseOtpFunctionFire, setFirebaseOtpFunctionFire] = useState(false)

  function onCaptchaVeriy() {
    if (!window.recaptchaVerifier) {

      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        'size': 'invisible',
        'callback': (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          onSignup();
        }
      });
    }
  }

  function onSignup(phoneNumber) {
    onCaptchaVeriy()

    const appVerifier = window.recaptchaVerifier

    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationresult) => {

        window.confirmationresult = confirmationresult
        console.log("Sent")

        setFirebaseOtpFunctionFire(false)

      }).catch((err) => {
        // console.error("error")
        console.log(err.message)
        // if (err.message === "reCAPTCHA client element has been removed: 0") {
        //   alert("It seems like you have a slow internet connection")
        // }
        // else if (err.message === "Firebase: Error (auth/too-many-requests).") {
        //   alert("You have tried too many times please use another number or try after some tim with this number")
        // }

      })
  }

  function phoneNumberCheck(event) {

    // event.preventDefault()

    if (event.key === 'Enter') {

      setFirebaseOtpFunctionFire(true)

      const newPhoneNumber = "+91" + phoneNumber

      if (newPhoneNumber.length === 13) {

        onSignup(newPhoneNumber)

      } else {
        console.log("Not a phone number")
      }

    }
  }

  return (
    <div className="login-container" style={{ display: userComponent ? "block" : "none", scrollbarWidth: 'none' }}>
      <div className="login-section">
        <div className="login-menu">
          <p className="login-left">Get Started</p>
          <i className="fa-solid fa-xmark" onClick={() => { dispatch(updateUserComponent()) }}></i>
        </div>
        <div className="login-type">
          <div className="login-with-google">
            <img
              src="https://in.bmscdn.com/webin/common/icons/googlelogo.svg"
              alt="google logo"
            />
            <p>Continue with Google</p>
          </div>

          <div className="login-with-email">
            <img
              src="https://in.bmscdn.com/webin/common/icons/email.svg"
              alt="email logo"
            />
            <p>Continue with Email</p>
          </div>

          <div className="login-with-guest">
            <img
              src="https://icons.veryicon.com/png/o/miscellaneous/youyinzhibo/guest.png"
              alt="email logo"
            />
            <p>Continue As a Guest</p>
          </div>

          <div className="or">
            <p>OR</p>
          </div>

          <div className="login-with-phone">
            <div className="india-img">
              <img src={IndiaImg} alt="India Img" />
              <span>+91</span>
            </div>

            <div className="ph-number">
              <input
                type="number"
                placeholder="Continue with mobile number"
                onChange={(e) => { setPhoneNumber(e.target.value) }}
                onKeyDown={(e) => { phoneNumberCheck(e) }} />
            </div>
          </div>

          <div className="ph-next-button-container">
          <button className="ph-next-button" name="Phone next button">
            Next
          </button>
          </div>
        </div>
        <div className="agree-message">
          <input type="checkbox" name="" id="" />
          <p >
            I agree to the <span>
              <Link to={'/privacy-policy'} style={{ textDecoration: 'none', color: 'black' }}>Terms & Conditions</Link>
            </span> &{" "}
            <span><Link to={'/privacy-policy'} style={{ textDecoration: 'none', color: 'black' }}>Privacy Policy</Link></span>
          </p>
        </div>
      </div>
      <div id='recaptcha-container' style={{ display: firebaseOtpFunctionFire ? "block" : 'none' }} />
    </div>
  );
};

export default Login;
