import React, { useState } from 'react'
import './Impacto.scss'
import FarmerPhoto from '../../../assets/Farmer.jpg'
import GoogleIcon from '@mui/icons-material/Google';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PSStore from '../../../assets/play-store-logo-33874.png'
import partnerOne from '../../../assets/partnerIcons/1a.jpg'
import partnerTwo from '../../../assets/partnerIcons/1b.jpg'
import partnerThree from '../../../assets/partnerIcons/1c.jpg'
import partnerFour from '../../../assets/partnerIcons/1d.jpg'
import partnerFive from '../../../assets/partnerIcons/1e.jpg'
import partnerSix from '../../../assets/partnerIcons/1f.jpg'
import appImage from '../../../assets/AppImage.png'
// import tempimage from '../../../assets/FarmerPhotoTwo.jpg'
import BoxIcon from '../../../assets/icon/iconsContainerIcon.png';
import tractorIcon from '../../../assets/icon/tractorIcon.png'
import farmerIcon from '../../../assets/icon/farmer.png'
import communityIcon from '../../../assets/icon/partners.png'
import { Link } from 'react-router-dom';

const Farmer = () => {

  const boxObject = [
    {
      id: 1,
      heading: "Entrenamiento y soporte",
      subheading: {
        one: "Programas de formación y talleres destinados  ",
        two: "a mejorar las habilidades y conocimientos de los agricultores"
      }
    },
    {
      id: 2,
      heading: "Servicios Agrícolas",
      subheading: {
        one: "Nuestros servicios ofrecen orientación, capacitación y",
        two: "soporte técnico en diversas prácticas agrícolas."
      }
    },
    {
      id: 3,
      heading: "Soporte financiero",
      subheading: {
        one: "Acceso a crédito asequible y asistencia financiera",
        two: "préstamos, subvenciones, subvenciones y planes de seguros"
      }
    },
    {
      id: 4,
      heading: "Adopción de tecnología",
      subheading: {
        one: "Las tecnologías ayudan a tomar decisiones",
        two: "informadas, optimizar el uso de recursos y la productividad."
      }
    },
  ]

  const registerProcessBox = [
    {
      id: 1,
      heading: "1.Acceso a recursos",
      subheading: {
        one: "Los programas comunitarios pueden proporcionar a los agricultores acceso a varios recursos que son cruciales para sus operaciones. Esto puede incluir equipos agrícolas, maquinaria, sistemas de riego, fertilizantes y semillas. Al tener acceso a estos recursos, los agricultores pueden mejorar su productividad y eficiencia.",
      }
    },
    {
      id: 2,
      heading: "2. Conocimiento y Formación",
      subheading: {
        one: "Los programas comunitarios a menudo ofrecen sesiones de capacitación, talleres y seminarios sobre diversas prácticas, técnicas y tecnologías agrícolas. Estos programas pueden educar a los agricultores sobre los últimos avances en agricultura, prácticas sostenibles y manejo eficiente de cultivos. Al adquirir conocimientos y habilidades, los agricultores pueden mejorar sus prácticas agrícolas y aumentar sus rendimientos.",
      }
    },
    {
      id: 3,
      heading: "3. Acceso al mercado",
      subheading: {
        one: "Los programas comunitarios pueden ayudar a los agricultores a conectarse con los mercados y consumidores locales. Estos programas pueden facilitar la participación de los agricultores en los mercados de agricultores u otras iniciativas directas al consumidor.",
      }
    },
  ]

  const [paragraphMoreShow, setParagraphMoreShow] = useState(false)
  const [paragraphMoreShowBoxId, setParagraphMoreShowBoxId] = useState([])

  function showCompleteParagraph(boxId){
    setParagraphMoreShow(true)
    setParagraphMoreShowBoxId(boxId)
  }

  const breakpoints = {
    320: {
      slidesPerView: 1,
      spaceBetween: 20
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 40
    },
    1000: {
      slidesPerView: 3,
      spaceBetween: 50
    }
  };

  const [swiper, setSwiper] = useState()
  const [partnerSwiper, setPartnerSwiper] = useState()

  function handleNext() {
    swiper.slideNext();
  }
  function handlePrev() {
    swiper.slidePrev();
  }

  function handlePartnerNext() {
    partnerSwiper.slideNext();
  }
  function handlePartnerPrev() {
    partnerSwiper.slidePrev();
  }

  const swiperSlideObject = [
    {
      id: 1,
      tempimage: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/g9.jpeg",
      heading: "",
      subheading: {
        one: "",
      }
    },
    {
      id: 2,
      tempimage: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/g1.jpeg",
      heading: "",
      subheading: {
        one: "",
      }
    },
    {
      id: 3,
      tempimage: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/g7.jpeg",
      heading: "",
      subheading: {
        one: "",
      }
    },
    {
      id: 4,
      tempimage: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/g8.jpeg",
      heading: "",
      subheading: {
        one: "",
      }
    },
  ]

  const partnerIcons = [
    {
      icon: partnerOne
    },
    {
      icon: partnerTwo
    },
    {
      icon: partnerThree
    },
    {
      icon: partnerFour
    },
    {
      icon: partnerFive
    },
    {
      icon: partnerSix
    },
  ]

  return (
    <div className='Farmer'>

      <div className="Container">

        <div className="FarmerPhoto">

          <img src={FarmerPhoto} alt="" />

          <div className="BookTractor">
            <Link to={"/search"} style={{ textDecoration: "none" }}>
              <span>Tractor de libros</span>
            </Link>
          </div>

        </div>

        <div className="HOne">
          <span>Comunidades rurales</span>
          {/* <span>(Point of Sales Person)</span> */}
        </div>

        <p className='POne'>
          Las comunidades rurales dependen en gran medida de la agricultura como fuente principal de sustento.
        </p>
        <p className='POne'>Los agricultores de estas áreas a menudo enfrentan desafíos únicos y requieren varias formas de asistencia para prosperar.
        </p>

        <div className="Boxes">

          {
            boxObject.map((box) => {
              return (
                <div className="Box" key={box.id}>
                  <div className="IconContainer">
                    <img src={BoxIcon} alt="" />
                  </div>
                  <div className="heading">{box.heading}</div>
                  <div className="sub-heading">
                    <div>{box.subheading.one}</div>
                    <div>{box.subheading.two}</div>
                  </div>
                </div>
              )
            })
          }

        </div>

        <div className="greenBannerSection">

          <p>Nosotros servimos</p>

          <div className="boxes">

            <div className="box">

              <img src={tractorIcon} className='box-left' />

              <div className="box-right">
                <div className="first-line">50+</div>
                <div className="second-line">Servicios de reserva</div>
              </div>

            </div>

            <div className="box">

              <img src={farmerIcon} className='box-left' />

              <div className="box-right">
                <div className="first-line">1000+</div>
                <div className="second-line">Agricultores rurales</div>
              </div>

            </div>

            <div className="box">

              <img src={communityIcon} className='box-left' />

              <div className="box-right">
                <div className="first-line">15+</div>
                <div className="second-line">Comunidad rural</div>
              </div>

            </div>

          </div>

        </div>

        <div className="send-otp-box">

          <div className="left">
            <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fholatractor%2Fvideos%2F991073688753606%2F&show_text=0&width=500" width="500" height="280" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowTransparency="true" allowFullScreen="true"></iframe>
          </div>

          <div className="right">

            <div className="firstline">

              <div>Servicios de campo para los campesinos.</div>
              <div>Una mayor eficiencia puede conducir a una mayor productividad en la granja</div>

            </div>

            <Link to={"/search"} style={{ textDecoration: "none" }}>
              <div className="secondline">
                <span>Tractor de libros</span>
              </div>
            </Link>

          </div>

        </div>

        <div className="register-process">

          <p>Cómo los agricultores pueden realmente beneficiarse de los programas comunitarios</p>

          <div className="middle-container">

            {
              registerProcessBox.map((box) => {

                const trimText = box.subheading.one.slice(0, 150)

                return (
                  <div className="Box" key={box.id}>
                    <div className="IconContainer">

                    </div>
                    <div className="heading">{box.heading}</div>
                    <div className="sub-heading">

                      {
                        (paragraphMoreShow && (box.id === paragraphMoreShowBoxId)) ?

                          <div>{box.subheading.one}</div>

                          :

                          <div>{trimText}
                            <span style={{
                              textDecoration: 'underline',
                              cursor: 'pointer'
                            }}
                            onClick={()=>{showCompleteParagraph(box.id)}}>
                              read more
                            </span>
                          </div>
                      }
                    </div>
                  </div>
                )
              })
            }

          </div>

          <div className='buttom-button'>
            <span>Únete a nosotros ahora</span>
          </div>

        </div>

        <div className="Testimonials">

          <p className='main-heading'>Nuestro programa comunitario para agricultores rurales</p>
          <p className='sub-heading'>proporcionar un entorno de apoyo para los agricultores</p>

          <div className="leftButton" onClick={() => { handlePrev() }}>
            <ChevronLeftIcon />
          </div>
          <div className="rightButton" onClick={() => { handleNext() }}>
            <ChevronRightIcon />
          </div>

          <Swiper
            className="Swiper"
            // modules={[Navigation]}
            // navigation 
            slidesPerView={3}
            spaceBetween={30}
            breakpoints={breakpoints}
            onSwiper={(swiper) => {
              setSwiper(swiper)
            }}
            onSlideChange={() => { }}
          >

            {
              swiperSlideObject.map((box) => {
                return (
                  <SwiperSlide className="Box" key={box.id}>
                    <div className="IconContainer">
                      <img src={box.tempimage} alt="" />
                    </div>
                    <div className="heading">{box.heading}</div>
                    <div className="sub-heading">
                      <div>{box.subheading.one}</div>
                    </div>
                  </SwiperSlide>
                )
              })
            }

          </Swiper>

        </div>

        <div className="partners">

          <p className="mainHeading">Nuestros socios de confianza</p>
          {/* <p className="subHeading">We’re associated with India’s 40+ popular insurance companies</p> */}

          <div className="leftButton" onClick={() => { handlePartnerPrev() }}>
            <ChevronLeftIcon />
          </div>
          <div className="rightButton" onClick={() => { handlePartnerNext() }}>
            <ChevronRightIcon />
          </div>

          <Swiper
            slidesPerView={'auto'}
            spaceBetween={50}
            // breakpoints={breakpoints}
            onSwiper={(swiper) => {
              setPartnerSwiper(swiper)
            }}
          >

            {
              partnerIcons.map((icons) => {
                return (
                  <SwiperSlide key={icons.icon}>
                    <img src={icons.icon} alt="" />
                  </SwiperSlide>
                )
              })
            }

          </Swiper>

        </div>

        <div className="appDownloadPromotion">

          <p className="mainHeading">aplicación holatractor</p>

          <div className="subHeading">
            <span>
              Fácil de usar y confiable, la aplicación fue construida
            </span>
            <span>
              para agricultores, propietarios de comunidades y propietarios de tractores
            </span>
          </div>

          <a className="pstore-logo" href="https://play.google.com/store/apps/details?id=com.nims.holatractor">
            <img src={PSStore} alt="" />
          </a>

          <img src={appImage} alt="" className='image' />

        </div>

      </div>

    </div>
  )
}

export default Farmer