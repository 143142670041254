import React from 'react'
import PalmTreeProcess from '../PalmTreeProcess/PalmTreeProcess'
import { useSelector } from 'react-redux'

const MMMp = () => {

  const languageOption = useSelector(state => state.language.language)

  return (
    <div 
    className='w-full relative before:absolute before:bottom-0 before:left-0 before:right-0 before:m-auto before:h-[3px] before:w-[180px] before:bg-[#00A651] before:rounded-full'>

            <div className='w-[90%] 900px:w-[80%] mx-auto flex flex-col items-center justify-center gap-[30px] py-[40px]'>

                <div 
                className='font-bold text-[18px] 500px:text-[24px] 1100px:text-[27px] 1400px:text-[28px] 1500px:text-[30px] text-[#4D4D4D] w-[90%] 600px:w-[500px] relative before:absolute before:left-[-30px] before:h-full before:w-0 600px:before:w-[3px] before:rounded-full before:bg-[#2E3192] my-[30px]'>

                  {
                    languageOption === 'English' ?
                    
                    <spna><span className='text-[#2E3192]'>Agroforestry</span> HolaTractor services can be used to tow tree planting equipment, including tree planters and augers, to dig holes and plant trees in designated agroforestry areas and mulching attachments can clear undergrowth, creating space for tree growth.Our tractors can also tow pruning equipment for shaping and maintaining tree canopies.</spna>
                    :
                    <spna>Los servicios de <span className='text-[#2E3192]'>Agroforestería</span> HolaTractor se pueden utilizar para remolcar equipos de plantación de árboles, incluidos plantadores de árboles y barrenas, para cavar hoyos y plantar árboles en áreas agroforestales designadas y los accesorios de abono pueden limpiar la maleza, creando espacio para el crecimiento de los árboles. Nuestros tractores también pueden remolcar equipos de poda para dar forma y mantenimiento de las copas de los árboles.</spna>
                  }

                </div>

            </div>

            <PalmTreeProcess />

        </div>
  )
}

export default MMMp