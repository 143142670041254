import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const SecondComponent = () => {

    const headingArray = [
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Orientación experta",
            para: "Nuestros agentes de soporte capacitados conocen bien todos los aspectos de nuestro sistema SaaS, lo que garantiza que reciba orientación y asistencia experta siempre que la necesite."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Optimización de la eficiencia",
            para: "Entendemos la importancia de la eficiencia en las operaciones agrícolas. Es por eso que nos dedicamos a ayudarlo a aprovechar al máximo nuestro sistema SaaS, agilizar los procesos y aumentar la productividad."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Soporte personalizado",
            para: "No hay dos granjas iguales, ni tampoco nuestros servicios de soporte. Nos tomamos el tiempo para comprender sus necesidades únicas y adaptar nuestra asistencia en consecuencia, asegurándonos de que reciba el apoyo personalizado que se merece."
        },
        {
            icon: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/graph.svg",
            heading: "Reserva sin problemas",
            para: "¡Experimenta reservas sin problemas con nuestro sistema perfecto! Diga adiós a los dolores de cabeza por la programación, ya que nuestra plataforma intuitiva simplifica la gestión de citas, ahorrándole tiempo valioso y eliminando la frustración."
        },
    ]

    return (
        <div className='SecondComponent'>

            <div
                className='container'>

                <p className='mainHeading'>
                    ¡Servicio de agente de soporte avanzado del sistema SaaS de Holatractor!
                </p>


                <p className='subHeading'>
                    Estamos aquí para revolucionar la forma en que los agricultores y los clientes interactúan con la tecnología agrícola. Nuestro soporte de sistema SaaS (software como servicio) de vanguardia garantiza procesos de reserva fluidos y maximiza la eficiencia en el uso del sistema, permitiéndole concentrarse en lo que más importa: su granja.
                </p>

                <div
                    className='elementsContainer'>

                    <div className='left'>

                        <img
                            src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/hola.webp"
                            alt='left side image' />

                    </div>

                    <div
                        className='right'>

                        {
                            headingArray.map((details, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='box'>

                                        <div className="box-left">
                                            <img src={details.icon} alt="" />
                                        </div>

                                        <div className="box-right">

                                            <div className="heading">
                                                {details.heading}
                                            </div>

                                            <div className="details">
                                                {details.para}
                                            </div>

                                        </div>

                                    </div>
                                )
                            })
                        }

                    </div>

                </div>

            </div>

        </div>
    )
}

export default SecondComponent