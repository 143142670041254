import React from 'react'
import './DenuDelTractor.scss'
import TopImageComponent from './TopImageComponent'
import FirstComponent from './FirstComponent'
import SeconsComponent from './SeconsComponent'
import ThirdComponent from './ThirdComponent'
import PricingComponent from '../ServiciosDeSeguimineto/PricingComponent'

const DenuDelTractor = () => {
  return (
    <div className='DenuDelTractor'>

        <TopImageComponent />

        <FirstComponent />

        <SeconsComponent />

        <ThirdComponent />

        {/* <PricingComponent /> */}

    </div>
  )
}

export default DenuDelTractor