import React from 'react'
import './PostRequirementOfTractor.scss'
import { motion } from 'framer-motion'
import SecondComponent from './SecondComponent'
import ThirdComponent from './ThirdComponent'

const PostRequirementOfTtractor = () => {
  return (
    <div className="PostRequirementOfTtractor">

        <div className="banner">

            <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_group.webp" alt="banner image" />

            <div className='shadow' />

            <motion.div
            initial={{
                x: -100,
                opacity: 0
            }}
            whileInView={{
                x: 0,
                opacity: 1
            }}
            transition={{
                duration: 1
            }}
            className='bannerTextContainer'>

                <div className='bannerText'>
                    <p>
                    Requisito de publicación
                    </p>
                    <p>
                    Impulsando tractores comunitarios para el crecimiento comunitario
                    </p>
                    <button
                    name="publicación comunitaria button">
                    Publicación comunitaria
                    </button>
                </div>

            </motion.div>

        </div>

        <SecondComponent />

        <ThirdComponent />

    </div>
  )
}

export default PostRequirementOfTtractor