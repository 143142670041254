import React from 'react'

const FourthComponent = () => {
    return (
        <div className='FourthComponent'>

            <div className="container">

                {/* Heading */}
                <h1>Beneficios para nuestra comunidad</h1>

                <div className='box'>

                    <div className="box-container">

                        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/farmer_benefit1.webp" alt="temp" />
                        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/benefit_2.webp" alt="temp" />
                        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/benefit_3.webp" alt="temp" />
                        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/benefit_4.webp" alt="temp" />
                        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/benefit_5.webp" alt="temp" />
                        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/benefit_6.webp" alt="temp" />
                        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/benefit_7.webp" alt="temp" />

                    </div>

                </div>

                <div className='paragraphs-container'>
                    <p>
                    Holatractor se dedica a brindar a nuestra comunidad los tractores y el conocimiento necesarios para prosperar en un clima cambiante. Al adoptar los avances tecnológicos y fomentar prácticas sostenibles, podemos crear un futuro mejor para la agricultura y nuestro planeta.
                    </p>
                    <p>
                    No se trata sólo de conectar a los agricultores con los tractores. Nos apasiona potenciar un futuro sostenible para la agricultura. Creemos que la tecnología y la innovación desempeñan un papel crucial para abordar el cambio climático y promover prácticas agrícolas climáticamente inteligentes.
                    </p>
                </div>

            </div>

        </div>
    )
}

export default FourthComponent