import React, { useState } from 'react'

const ThirdComponent = () => {

    const [activeContentHeading, setActiveContnentHeading] = useState(0)

    const contentArray = [
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/profile1.png",
            heading: "Crear un perfil en hola tractor",
            para: "Crear un perfil con Hola Tractor es rápido y sencillo. Simplemente visite nuestro sitio web o descargue la aplicación, luego siga las instrucciones para registrarse. Proporcione información básica como su nombre, dirección de correo electrónico y ubicación."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
            heading: "Únete a tu comunidad",
            para: "Una vez registrado, puede conectarse fácilmente con grupos comunitarios cercanos, propietarios o inquilinos, que ofrecen o buscan servicios asequibles."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/listings.png",
            heading: "Crear un listado detallado",
            para: "Planifique detalles esenciales como el tipo de tractor que necesita, la duración del alquiler, las tareas específicas para las que se utilizará, su ubicación y su presupuesto. La plataforma de Hola Tractor lo conectará con propietarios de tractores cercanos que cumplan con sus criterios, permitiéndole seleccionar la opción más adecuada."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
            heading: "Seleccione un tipo de tractor",
            para: "Explore los listados disponibles y seleccione el tipo de tractor que satisfaga sus necesidades."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/attachment.png",
            heading: "Seleccione si hay algún archivo adjunto",
            para: "Hola Tractor ofrece una amplia gama de maquinaria de acoplamiento para tractores, brindando a los agricultores soluciones versátiles para satisfacer sus necesidades agrícolas. Con solo unos pocos clics, los usuarios pueden explorar nuestro inventario de accesorios, incluidos arados, cultivadores, sembradoras y más. Simplemente seleccione el equipo deseado."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/duration.png",
            heading: "Especificar duración del alquiler",
            para: "Simplemente especifique la duración de alquiler deseada al enviar sus requisitos. Nuestra plataforma lo conecta con propietarios de tractores cercanos que pueden adaptarse a su calendario."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/tasks.png",
            heading: "Describir las tareas para las que se utilizará",
            para: "Hola Tractor se utilizará para una variedad de tareas que incluyen arar, labrar, rastrillar, plantar y cultivar campos y muchas más. Su versatilidad le permite manejar diversas actividades agrícolas de manera eficiente y efectiva, contribuyendo a una mayor productividad y rendimiento para los agricultores."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/budget.png",
            heading: "Especifica tu presupuesto",
            para: "Especifique su presupuesto junto con sus requisitos para el tractor. Hola Tractor ofrece una gama de opciones asequibles adaptadas a sus necesidades. Es un proceso rápido y eficiente que le garantiza obtener el tractor que necesita a un precio asequible."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/location.png",
            heading: "Especifica tu ubicación",
            para: "Para alquiler de tractores con Hola Tractor, simplemente especifique su ubicación y requisitos en nuestra plataforma. Lo conectaremos con propietarios de tractores cercanos que ofrecerán tarifas asequibles."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/preview_like.png",
            heading: "Obtenga una vista previa de sus requisitos y publíquelos",
            para: "Obtenga una vista previa de sus requisitos y publíquelos en nuestra plataforma. Incluya detalles como el tipo de tractor que necesita, la duración del alquiler, las tareas para las que se utilizará y su presupuesto. Nuestro avanzado sistema de búsqueda lo conectará con propietarios de tractores cercanos que ofrecerán tarifas asequibles."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/buisness.png",
            heading: "Obtenga conexiones de tractor para su oferta",
            para: "Conéctese con propietarios de tractores rápida y fácilmente a través de Hola Tractor para todas sus necesidades. Con Hola Tractor, alquilar un tractor es un proceso perfecto."
        },
    ]

    return (
        <div className='ThirdComponent'>

        <div className="headings">

            <h2>
            Optimice sus necesidades
            </h2>

            <p>
                Ofrecemos un proceso simplificado para publicar sus requisitos y conectarse con propietarios de tractores cercanos a costos asequibles. Simplemente proporcione detalles sobre sus necesidades, incluido el tipo de tractor requerido, la duración del alquiler, las tareas para las que se utilizará y su presupuesto. Nuestra plataforma filtra listados según la ubicación, lo que garantiza que encuentre tractores cercanos. Puede comparar fácilmente opciones y seleccionar la más adecuada.
            </p>

        </div>

            <div className="Container">

                <div className="left-container">

                    <div className="list-container">
                        {
                            contentArray.map((details, index)=>{
                                return(
                                    <div
                                    className='box'
                                    key={index}
                                    onClick={()=>{setActiveContnentHeading(index)}}>
                                        <div 
                                        className='heading' 
                                        style={{
                                            color: activeContentHeading === index ? 'white' : 'rgba(255, 255, 255, 0.497)'
                                        }}>
                                            <p>{index+1 > 9 ? index+1 : `0${index+1}`}.</p>
                                            <p>{details.heading}</p>
                                        </div>
                                        <div className='box-bottom' style={{
                                            backgroundColor: activeContentHeading === index ? 'white' : 'rgba(255, 255, 255, 0.497)'
                                        }} />
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>

                <div className="right-container">

                    <div className='contents'>

                        <img 
                        src={contentArray[activeContentHeading].img} 
                        alt="" />

                         <div className="Heading">
                            {contentArray[activeContentHeading].heading}
                         </div>

                         <div className="Paragraph">
                            {contentArray[activeContentHeading].para}
                         </div>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default ThirdComponent