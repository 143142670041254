import React from 'react'
import './Service.scss'
import serviceBackgroundVideo from '../../../assets/video/service.mp4'
import MiddleBtn from '../../../components/Home/HomeMiddle/MiddleButton/MiddleBtn'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useState } from 'react';
import ServiceFooter from './ServiceFooter';

const Service = () => {

    const boxArray = [
        {
            name: 'Cloud',
            bgImage: 'https://wallpapercave.com/wp/wp8199655.jpg'
        },
        {
            name: 'Cloud',
            bgImage: 'https://wallpapercave.com/wp/wp8199655.jpg'
        },
        {
            name: 'Cloud',
            bgImage: 'https://wallpapercave.com/wp/wp8199655.jpg'
        },
        {
            name: 'Cloud',
            bgImage: 'https://wallpapercave.com/wp/wp8199655.jpg'
        },
        {
            name: 'Cloud',
            bgImage: 'https://wallpapercave.com/wp/wp8199655.jpg'
        },
        {
            name: 'Cloud',
            bgImage: 'https://wallpapercave.com/wp/wp8199655.jpg'
        },
        {
            name: 'Cloud',
            bgImage: 'https://wallpapercave.com/wp/wp8199655.jpg'
        },
        {
            name: 'Cloud',
            bgImage: 'https://wallpapercave.com/wp/wp8199655.jpg'
        },
        {
            name: 'Cloud',
            bgImage: 'https://wallpapercave.com/wp/wp8199655.jpg'
        },
        {
            name: 'Cloud',
            bgImage: 'https://wallpapercave.com/wp/wp8199655.jpg'
        },
    ]

    const [viewMoreButtonClicked, setViewMoreButtonClicked] = useState(false)
    const [filteredBox] = useState(boxArray[0])
    // console.log(filteredBox)

    function functionViewMoreButtonClicked() {
        viewMoreButtonClicked ? setViewMoreButtonClicked(false) : setViewMoreButtonClicked(true)
    }

    return (
        <div className='Service'>

            <div className="Container">

                <div className="video-section">

                    <video autoPlay loop muted>
                        <source src={serviceBackgroundVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                    <p>
                        Holatractor en Servicios de Tractores y Agricultura Generativa
                    </p>

                    <div className='button'>
                        <MiddleBtn />
                    </div>

                </div>

                {/* <div className="services-section">

                    <div className="heading-banner">Services</div>

                    <div className="service-options">

                        {
                            viewMoreButtonClicked ?
                                boxArray.map((fBox,index) => {

                                    return (
                                            <div
                                                className="o-box-container"
                                                style={{
                                                    backgroundImage: `url(${fBox.bgImage})`,
                                                }}
                                                key={index}>

                                                <span className="box-name">{fBox.name}</span>

                                            </div>
                                    )

                                })
                                :
                                boxArray.map((fBox,index) => {

                                    return (
                                            <div
                                                className="o-box-container"
                                                style={{
                                                    backgroundImage: `url(${fBox.bgImage})`,
                                                    display: index > 3 && 'none'
                                                }}
                                                key={index}>

                                                <span className="box-name">{fBox.name}</span>

                                            </div>
                                    )

                                })
                        }

                    </div>

                    <div className="view-more-button" onClick={() => { functionViewMoreButtonClicked() }}>

                        <span>
                            {
                                viewMoreButtonClicked ? 'View Less' : 'View More'
                            }
                        </span>

                        <ArrowDownwardIcon
                            style={{
                                transform: viewMoreButtonClicked && 'rotate(180deg)'
                            }} />

                    </div>

                </div> */}

                {/* <ServiceFooter /> */}

            </div>

        </div>
    )
}

export default Service