import React from 'react'

const AcaiComponentFour = () => {

    const contentArray = [
        {
            imageLink: "https://absi.global/wp-content/uploads/2020/08/pop-mobile-apps.png",
            imageHeader: "imageHeader"
        },
        {
            imageLink: "https://absi.global/wp-content/uploads/2020/08/pop-mobile-apps.png",
            imageHeader: "imageHeader"
        },
        {
            imageLink: "https://absi.global/wp-content/uploads/2020/08/pop-mobile-apps.png",
            imageHeader: "imageHeader"
        },
        {
            imageLink: "https://absi.global/wp-content/uploads/2020/08/pop-mobile-apps.png",
            imageHeader: "imageHeader"
        },
        {
            imageLink: "https://absi.global/wp-content/uploads/2020/08/pop-mobile-apps.png",
            imageHeader: "imageHeader"
        },
        {
            imageLink: "https://absi.global/wp-content/uploads/2020/08/pop-mobile-apps.png",
            imageHeader: "imageHeader"
        },
        {
            imageLink: "https://absi.global/wp-content/uploads/2020/08/pop-mobile-apps.png",
            imageHeader: "imageHeader"
        },
    ]

  return (
    <div className='w-[90%] 1000px:w-[80%] mx-auto py-[40px] flex items-center justify-center gap-[40px] flex-wrap'>

        {
            contentArray.map((content, index)=>{
                return (
                    <div
                    key={index}
                    className='flex flex-col items-center justify-center gap-[20px]'>

                        <img 
                        src={content.imageLink} 
                        alt=""
                        className='w-[140px]' />

                        <p className='font-bold text-[20px] text-[#23A455]'>
                            {content.imageHeader}
                        </p>

                    </div>
                )
            })
        }

    </div>
  )
}

export default AcaiComponentFour