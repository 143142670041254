import React from 'react'
import { useSelector } from 'react-redux'

const Capabilities = () => {

    const languageOption = useSelector(state => state.language.language)

    const capabilitiesArray = languageOption === 'English' ? [
        {
            heading: "Remote Monitoring",
            content: "IoT device can be installed on the tractor. These sensors of IOT device can monitor parameters like temperature, pressure, and usage metrics,location etc. The data collected can be sent to a central server or a cloud platform, allowing collector and palm vendor to remotely monitor the tractor's performance,location and amount of palm collector in real-time."
        },
        {
            heading: "Precision recollection",
            content: "IoT devices can be used for precision re-collection techniques. Sensors on the IOT device can measure different data. This data, combined with GPS information, can create detailed maps of the re-collection location , enabling precision re-collection. This leads to optimized resource usage and increased palm recollection."
        },
        {
            heading: "Fleet Management",
            content: "If there are multiple tractors on the field or jungle , IoT technology can be used to manage the entire fleet efficiently. Fleet tracking systems can monitor the location, speed, and utilization of each tractor, helping re-collector optimize routes, reduce fuel consumption, and improve overall productivity."
        },
        {
            heading: "Data Analysis and Decision Support",
            content: "The data collected from the tractor's IoT devices can be analyzed to gain insights into field operations. Machine learning algorithms can help in making data-driven decisions, optimizing re-collector practices, and increasing overall productivity."
        },
        {
            heading: "Environmental Monitoring",
            content: "IoT device can also monitor environmental factors such as weather conditions and air quality. This data can be used to make informed decisions about re-collection schedules ensuring optimal conditions for recollection growth."
        },
        {
            heading: "Safety and Security",
            content: "IoT devices can enhance the safety and security of the tractor and the field. For example, sensors can detect sudden movements or unauthorized access, triggering alarms or notifications to the re-collector or security personnel."
        },
    ]
    :
    [
        {
            heading: "Monitoreo remoto",
            content: "Se puede instalar un dispositivo IoT en el tractor. Estos sensores del dispositivo IOT pueden monitorear parámetros como temperatura, presión y métricas de uso, ubicación, etc. Los datos recopilados se pueden enviar a un servidor central o una plataforma en la nube, lo que permite al recolector y al proveedor de palma monitorear de forma remota el rendimiento, la ubicación y la cantidad del tractor. del recolector de palma en tiempo real."
        },
        {
            heading: "Recuerdo de precisión",
            content: "Los dispositivos IoT se pueden utilizar para técnicas de recolección de precisión. Los sensores del dispositivo IoT pueden medir diferentes datos. Estos datos, combinados con la información del GPS, pueden crear mapas detallados de la ubicación de recolección, lo que permite una recolección precisa. Esto conduce a un uso optimizado de los recursos y a una mayor recolección de la palma de la mano."
        },
        {
            heading: "Gestión de flotas",
            content: "Si hay varios tractores en el campo o en la jungla, la tecnología IoT se puede utilizar para gestionar toda la flota de manera eficiente. Los sistemas de seguimiento de flotas pueden monitorear la ubicación, la velocidad y la utilización de cada tractor, lo que ayuda al recolector a optimizar las rutas, reducir el consumo de combustible y mejorar la productividad general."
        },
        {
            heading: "Análisis de datos y soporte de decisiones",
            content: "Los datos recopilados de los dispositivos IoT del tractor se pueden analizar para obtener información sobre las operaciones de campo. Los algoritmos de aprendizaje automático pueden ayudar a tomar decisiones basadas en datos, optimizar las prácticas de recolección y aumentar la productividad general."
        },
        {
            heading: "Monitoreo ambiental",
            content: "El dispositivo IoT también puede monitorear factores ambientales como las condiciones climáticas y la calidad del aire. Estos datos se pueden utilizar para tomar decisiones informadas sobre los programas de recolección que garanticen las condiciones óptimas para el crecimiento de la recolección."
        },
        {
            heading: "Seguridad y proteccion",
            content: "Los dispositivos IoT pueden mejorar la seguridad del tractor y del campo. Por ejemplo, los sensores pueden detectar movimientos repentinos o accesos no autorizados, activando alarmas o notificaciones al recolector o al personal de seguridad."
        },
    ]

    return (
        <div className='py-[40px] w-full'>

            <div className='w-[90%] 1000px:w-[80%] mx-auto flex flex-col gap-[30px]'>

                <p 
                className='text-[22px] 500px:text-[26px] 700px:text-[30px] 900px:text-[36px] font-bold'>
                    Capabilities of our software and IoT (Internet of Things) device
                </p>

                {/* <p className='text-[18px] 700px:text-[20px]'>
                    Our dedicated software development team can back you up round the clock to build custom technology solutions specific to your industry and business
                </p> */}

                <div className='w-full grid grid-cols-1 700px:grid-cols-2 1000px:grid-cols-3 gap-8'>

                    {
                        capabilitiesArray.map((capability, index) => {
                            return (
                                <div className='w-full flex flex-col gap-[30px]' key={index}>

                                    {/* <img
                                        src={capability.image}
                                        alt=""
                                        className='w-[60px]'
                                    /> */}

                                    <p className='font-bold text-[20px] 700px:text-[22px]'>
                                        {capability.heading}
                                    </p>

                                    <p>
                                        {capability.content}
                                    </p>

                                </div>
                            )
                        })
                    }

                </div>

            </div>

        </div>
    )
}

export default Capabilities