import React from "react";
import "./postproperty.scss";
// import PropertyLeft from "./../../assets/g2.jpeg";

const PostProperty = () => {

  // const PropertyLeft= "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/55.png";

  return (
    <div className="post-property">
      <p>¿Eres dueño de un tractor?</p>
      <h2 className="heading">Alquila y gana con tu tractor más rápido con Holatractor</h2>
      <div className="container">
        <div className="left">
          <img src={"https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/rental_tractor.webp"} alt="property left img" />
        </div>
        <div className="right">
            <span>Publica tu tractor</span>
            <h2>Regístrese para listar su único tractor para <span>GRATIS</span></h2>
            <p>Ayude a los agricultores por su productividad y gane</p>
            <div className="p-btn">
                <button>Registrarse como propietario</button>
            </div>
        </div>
      </div>
    </div>
  );
};

export default PostProperty;
