import React from 'react'

const RegenerativeAgricultureTwo = () => {
  return (
    <div 
    className='w-[90%] 1000px:w-[80%] mx-auto py-[40px] flex flex-col gap-[20px] items-center justify-center'>

        <div className='w-[200px] h-[3px] bg-green-600 rounded-full' />

        <p className='text-[26px] font-bold 900px:text-[42px] 500px:text-[36px]'>
            Lorem, ipsum.
        </p>

        <p className='text-[22px] text-gray-600 text-center'>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tenetur, optio aliquid rerum dolore fugit iste sunt illo molestiae? Eveniet quibusdam error dolore totam officiis accusantium voluptas explicabo debitis reprehenderit recusandae molestiae eligendi odit cumque quaerat temporibus commodi vel quas, atque quam alias rem. Minus cum suscipit saepe, quibusdam similique mollitia?
        </p>
        
        <p className='text-[22px] text-gray-600 text-center'>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tenetur, optio aliquid rerum dolore fugit iste sunt illo molestiae? Eveniet quibusdam error dolore totam officiis accusantium voluptas explicabo debitis reprehenderit recusandae molestiae eligendi odit cumque quaerat temporibus commodi vel quas, atque quam alias rem. Minus cum suscipit saepe, quibusdam similique mollitia?
        </p>

    </div>
  )
}

export default RegenerativeAgricultureTwo