import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './Cookie.scss'
import Logo from '../../utils/nnacres_white_v2.png'
import { useNavigate } from 'react-router-dom'
import { updateCookieComponentShowToFalse } from '../../redux/cookieComponentShow'
import { useCookies } from 'react-cookie'

const Cookie = () => {

    const dispatch = useDispatch()
    const cookieComponent = useSelector(state => state.cookieComponentShow)

    const maxAgeInSeconds = 31536000; // Setting max age for 1 year

    const [cookies, setCookies] = useCookies(['cookieAccept'])

    const navigate = useNavigate();

    function cookieUpdate(){
        setCookies('cookieAccept' , 'accepted', {maxAge: maxAgeInSeconds})
        dispatch(updateCookieComponentShowToFalse())
    }

    return (
        <div
            style={{
                display: cookieComponent ? 'block' : 'none'
            }}
            className='Cookie'>

            <div className="companyLogo">
                <img src={Logo} alt="Logo" onClick={() => navigate("/")} />
            </div>

            <div className="desc">
                We use cookies to give you the best possible experience on our website.
            </div>

            <div className="P">
                When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer. To find out more, read our updated
            </div>

            <hr />

            <div className="buttons">

                <div className="denied">
                    Customize cookies
                </div>

                <div className="accepted" onClick={()=>{cookieUpdate()}}>
                    Accept all cookies
                </div>

            </div>

        </div>
    )
}

export default Cookie