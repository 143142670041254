import React from 'react'
import { useState } from 'react'

const SecondComponent = () => {

    const [activeContentHeading, setActiveContnentHeading] = useState(0)

    const contentArray = [
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/profile1.png",
            heading: "Recursos en línea",
            para: "Holatractor es una plataforma integral en línea que proporciona información y recursos sobre el uso, mantenimiento, resolución de problemas y pautas de seguridad de los equipos de tractores."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
            heading: "Demostraciones de campo",
            para: "Holatractor realiza demostraciones de campo y sesiones de capacitación práctica donde los miembros de la comunidad pueden aprender observando y participando en prácticas prácticas. Esto puede ayudar a reforzar el conocimiento teórico con aplicaciones en el mundo real y fomentar una mejor comprensión del funcionamiento del equipo del tractor."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/listings.png",
            heading: "Eventos comunitarios",
            para: "Holatractor organiza eventos comunitarios, como eventos agrícolas, ferias comerciales y exposiciones de equipos, donde los propietarios y operadores de tractores pueden interactuar con expertos, fabricantes y proveedores de la industria. Estos eventos pueden servir como valiosas oportunidades para establecer contactos y brindar acceso a los últimos avances en tecnología de tractores."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
            heading: "Foros en línea y redes sociales",
            para: "Holatractor crea y mantiene foros en línea, grupos de redes sociales y plataformas de discusión dedicados a los entusiastas de los equipos para tractores. Fomentar la participación activa y el intercambio de conocimientos dentro de estas comunidades, y brindar apoyo y asistencia oportuna a los miembros que buscan asesoramiento o soluciones a sus problemas."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/attachment.png",
            heading: "Manuales y guías de usuario",
            para: "Holatractor crea manuales y guías fáciles de usar que brindan instrucciones paso a paso para operar y mantener el equipo del tractor. Incluya ilustraciones, consejos para la solución de problemas y precauciones de seguridad para garantizar la facilidad de uso."
        },
    ]

    return (
        <div className='SecondComponent'>

        <div className="headings">

            <h2>
            Participación de la comunidad para una operación perfecta de los equipos de tractores
            </h2>

            <p>
            Holatractor tiene como objetivo fomentar una plataforma vibrante de participación comunitaria que integre a la perfección el conocimiento de la maquinaria para mejorar la facilidad de uso del equipo tractor. A través de esfuerzos colaborativos y experiencia compartida, los usuarios pueden acceder a una gran cantidad de información, consejos y mejores prácticas adaptadas a sus necesidades específicas. Al facilitar la comunicación abierta y la interacción entre los miembros, Holatractor crea un entorno de apoyo donde los usuarios pueden intercambiar ideas, solucionar problemas y mantenerse actualizados sobre las últimas innovaciones.
            </p>

        </div>

            <div className="Container">

                <div className="left-container">

                    <div className="list-container">
                        {
                            contentArray.map((details, index)=>{
                                return(
                                    <div
                                    className='box'
                                    key={index}
                                    onClick={()=>{setActiveContnentHeading(index)}}>
                                        <div 
                                        className='heading' 
                                        style={{
                                            color: activeContentHeading === index ? 'white' : 'rgba(255, 255, 255, 0.497)'
                                        }}>
                                            <p>{index+1 > 9 ? index+1 : `0${index+1}`}.</p>
                                            <p>{details.heading}</p>
                                        </div>
                                        <div className='box-bottom' style={{
                                            backgroundColor: activeContentHeading === index ? 'white' : 'rgba(255, 255, 255, 0.497)'
                                        }} />
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>

                <div className="right-container">

                    <div className='contents'>

                        <img 
                        src={contentArray[activeContentHeading].img} 
                        alt="" />

                         <div className="Heading">
                            {contentArray[activeContentHeading].heading}
                         </div>

                         <div className="Paragraph">
                            {contentArray[activeContentHeading].para}
                         </div>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default SecondComponent