import React from 'react'

const AcaiComponentTwo = () => {
  return (
    <div className='py-[40px] w-[90%] 1000px:w-[80%] mx-auto'>

        <p className='text-[22px] text-gray-600'>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellat quasi architecto eveniet consectetur, autem quia recusandae ipsam vitae tempore, blanditiis iusto quisquam cupiditate ullam amet eos consequuntur veritatis dicta voluptates harum deserunt. Aut illum id saepe repudiandae numquam cumque soluta recusandae, doloribus, mollitia delectus sunt nobis ipsum fuga excepturi natus dicta doloremque consequuntur enim ducimus voluptas accusamus libero perferendis! Rerum!
        </p>

        <img 
        src="https://absi.global/wp-content/uploads/2020/08/palm-oil-plantations-2-million-hectares.png" 
        alt=""
        className='w-full mt-[20px]' />

    </div>
  )
}

export default AcaiComponentTwo