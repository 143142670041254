import React from 'react'

const Benefits = () => {
  return (
    <div className='Benefits'>
      <div className="left">
        <div className="heading">Holatractor para agricultores Modelo de reparto</div>
        <div className='details-container'>
          <img src="" alt="" />
          <span className='span'>Ofrecemos tarifas competitivas para los servicios de reserva de tractores, haciéndolos accesibles a pequeños agricultores con presupuestos limitados.</span>
        </div>
        <div className='details-container'>
          <img src="" alt="" />
          <span className='span'>Nuestra plataforma fácil de usar facilita la reserva de tractores y la gestión de alquileres, ahorrándole tiempo y molestias.</span>
        </div>
        <div className='details-container'>
          <img src="" alt="" />
          <span className='span'>Nuestro equipo está aquí para ayudarle en cada paso del camino, desde reservar su tractor hasta resolver cualquier problema que pueda surgir.</span>
        </div>
      </div>
      <div className="right">
        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/traactor_book.svg" alt="" />
      </div>
    </div>
  )
}

export default Benefits