import React from 'react'
import { Link } from 'react-router-dom'

const FourthComponent = () => {
  return (
    <div className='FourthComponent'>

        <div className='container'>

        <p className='mainHeading'>
        Valoramos sus comentarios y consultas.
        </p>

        <p className='subHeading'>
        Si tiene alguna pregunta, sugerencia o inquietud, no dude en comunicarse con nosotros.
        </p>

        <Link to={'#'}>
          <button className=''>
          Contacta con nosotros
          </button>
        </Link>

        </div>

    </div>
  )
}

export default FourthComponent