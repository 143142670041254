import React from 'react'

const TopImageComponent = () => {
  return (
    <div className='TopImageComponent'>

        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/tractss.jfif" alt="" />

    </div>
  )
}

export default TopImageComponent