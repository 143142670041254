import React from 'react'
import './MiniTractor.scss'
import { Link } from 'react-router-dom'

const MiniTractor = () => {
    return (
        <div className='MiniTractor'>

            <div className="container">

                {/* <div className="main-heading">
                Tractores de dos ruedas
                </div> */}

                {/* <div className="sub-heading">
                ¡Cómo un tractor de dos ruedas puede potenciarle!
                </div> */}

                <div className="details-box">

                    <div className="left-details">

                        <div className="left-heading">
                        Tractores de dos ruedas
                        </div>

                        <div className="left-paragraph">
                        Todo agricultor sueña con una cosecha abundante, pero para los pequeños agricultores, los recursos limitados pueden parecer una barrera. Entendemos que los grandes resultados pueden surgir de pequeños comienzos. Por eso defendemos los tractores de dos ruedas, la solución perfecta para la agricultura a pequeña escala.
                        </div>

                        <Link to={'/mini-tractor'}>
                            <button
                                name='know more button'>
                                Ver más
                            </button>
                        </Link>

                    </div>

                    <div
                        className="right-details">
                        <img
                            src="https://t3.ftcdn.net/jpg/06/42/34/86/360_F_642348606_RY0LNG7axZA68QZbNRD1QDi5pahjbvzu.jpg"
                            alt="mini tractor" />
                    </div>

                </div>

            </div>

        </div>
    )
}

export default MiniTractor