import React, { useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch } from 'react-redux';
import { updateContactPageShow } from '../../redux/ContactPageShow';

const ContactBox = () => {

    const [messageSent, setMessageSent] = useState(false)

    const dispatch = useDispatch()

    function handleShowContactForm(e) {
        e.stopPropagation();
        dispatch(updateContactPageShow())
    }

  return (
    <div 
    className='fixed top-[10vh] w-[90%] 1000px:w-[1000px] left-[50%] -translate-x-1/2 h-[85vh] bg-white z-[100] drop-shadow-lg rounded-[20px] overflow-hidden'>

        <div
        className='w-full h-full relative'>

            <div className='absolute right-[30px] top-[20px] z-[120]' onClick={e=>{ handleShowContactForm(e) }}>

                <ClearIcon className='text-[#23A455] cursor-pointer' />

            </div>

            <div 
            className={`hidden 1000px:flex absolute ${messageSent ? 'translate-x-[600px]' : 'translate-x-0'} left-0 h-full bg-[#23A455] w-[400px] items-center justify-center flex-col font-bold  text-white transition-all duration-500`}>

                <div className='text-[36px] 1000px:text-[26px]'>
                    Hey!
                </div>

                <div className='text-[20px]'>
                    Do you want to get in touch
                </div>

                <div className='text-[20px]'>
                    Send a message
                </div>

                <div 
                className={`mt-[20px] text-[20px] border-[2px] border-white px-[20px] py-[8px] rounded-full cursor-pointer ${messageSent ? 'opacity-100' : 'opacity-0'} transition-all duration-500`}>

                    Sent

                </div>

            </div>

            <div
            className={`absolute ${messageSent ? '1000px:-translate-x-[400px]' : 'translate-x-0'} right-0 h-full w-full 1000px:w-[600px] flex items-center justify-center flex-col gap-[20px] transition-all duration-500`}>

                <div className='text-[26px] 1000px:text-[36px] font-bold text-[#23A455]'>
                    Get in touch with us
                </div>

                <div className='flex flex-col items-center justify-center gap-[14px]'>

                    <div>

                        <input 
                        type="text" 
                        name="Name" 
                        id="Name"
                        placeholder='Enter you name'
                        className='bg-gray-100 outline-none border-none px-[16px] py-[8px] w-[260px] 500px:w-[300px] rounded-[8px]' />

                    </div>

                    <div>

                        <input 
                        type="email" 
                        name="Email" 
                        id="Email"
                        placeholder='Enter your email'
                        className='bg-gray-100 outline-none border-none px-[16px] py-[8px] w-[260px] 500px:w-[300px] rounded-[8px]' />

                    </div>

                    <div>

                        <input 
                        type="text" 
                        name="Mobile_number" 
                        id="Mobile_number"
                        placeholder='Enter your mobile number' 
                        className='bg-gray-100 outline-none border-none px-[16px] py-[8px] w-[260px] 500px:w-[300px] rounded-[8px]' />

                    </div>

                    <div>

                        <textarea 
                        name="messageBox" 
                        id="messageBox"
                        rows="3" 
                        className='bg-gray-100 outline-none border-none px-[16px] py-[8px] w-[260px] 500px:w-[300px] rounded-[8px]' />

                    </div>

                </div>

                <button 
                name='Message send button'
                className='w-[260px] 500px:w-[300px] bg-[#23A455] text-white py-[10px] rounded-[8px] font-bold'
                onClick={()=>{setMessageSent(prev=>!prev)}}>
                    Send
                </button>

            </div>

        </div>

    </div>
  )
}

export default ContactBox