import React from 'react';
import "./showProperties.scss"
import Properties from "./Properties/Properties"
import RoomOne from "./../../../assets/g2.jpeg"
import RoomTwo from "./../../../assets/service1.jpg"
import RoomThree from "./../../../assets/service2.jpg"
import RoomFour from "./../../../assets/service3.jpg"
import RoomFive from "./../../../assets/service4.jpg"
import Room1 from "./../../../assets/service5.jpg"
import Room2 from "./../../../assets/service6.jpg"
import Room3 from "./../../../assets/service7.jpg"
import Room4 from "./../../../assets/service8.jpg"

const ServiciosMensuales = () => {
  return (
    <div className='properties-container'>
      <Properties image={RoomFive} period={"week"}/>
      <Properties image={RoomFour} period={"month"}/>
      <Properties image={RoomThree} period={"month"}/>
      <Properties image={RoomTwo} period={"week"}/>
      <Properties image={RoomOne} period={"week"}/>
      <Properties image={RoomFour} period={"week"}/>
      <Properties image={Room1} period={"month"}/>
      <Properties image={Room2} period={"month"}/>
      <Properties image={Room3} period={"week"}/>
      <Properties image={Room4} period={"week"}/>
      <Properties image={RoomThree} period={"month"}/>
      <Properties image={RoomTwo} period={"week"}/>
    </div>
  )
}

export default ServiciosMensuales