import React, { useEffect, useState } from "react";
import "./singleProperties.scss";
// import RoomImg from "./../../../assets/room-one.webp";
import RoomImg from "./../../../assets/TreactorImage.jpg";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import AcUnitOutlinedIcon from "@mui/icons-material/AcUnitOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
import SingleBedOutlinedIcon from "@mui/icons-material/RollerSkatingOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
// import SingleOne from "./../../../assets/single-room-one.webp";
import SingleOne from "./../../../assets/AttatchmentOne.jpg";
// import SingleTwo from "./../../../assets/single-room-two.webp";
import SingleTwo from "./../../../assets/AttatchmentTwo.jpg";
// import SingleThree from "./../../../assets/single-room-three.webp";
import SingleThree from "./../../../assets/AttatchmentThree.jpg";
// import SingleFour from "./../../../assets/single-room-four.webp";
import SingleFour from "./../../../assets/AttatchmentFour.jpg";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { BedOutlined } from "@mui/icons-material";
import SinglePropertyEffect from "../SimmerEffect/SinglePropertyEffect";

import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import { DateRangePickerCalendar, START_DATE } from "react-nice-dates";
import "react-nice-dates/build/style.css";

import { jsPDF } from "jspdf";

const SingleProperties = () => {
  const date = new Date().toLocaleDateString();
  const [dateOne, setDateOne] = useState(date);
  // const [dateTwo, setDateTwo] = useState("6/10/2023");
  const [dateTwo, setDateTwo] = useState(date);
  const [calShowHide, setCalShowHide] = useState(false);
  const [selectPerson, setSelectPerson] = useState(1);
  const [selectChild, setSelectChild] = useState(0);
  const [selectInfant, setSelectInfant] = useState(0);
  const [selectPet, setSelectPet] = useState(0);
  const [AttatchmentFour, setAttatchmentFour] = useState(0);
  const [guestToggle, setGuestToggle] = useState(false);
  const [roomPrice] = useState(500);
  const [cleaningFee] = useState(340);
  const [totalDays, setTotalDays] = useState(1);
  const [simmerEffect, setSimmerEffect] = useState(true);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [focus, setFocus] = useState(START_DATE);
  const handleFocusChange = (newFocus) => {
    setFocus(newFocus || START_DATE);
  };

  // const [TotalPrice] = useState( roomPrice+cleaningFee)

  const saveDate = () => {
    setDateOne(startDate);
    setDateTwo(endDate);
    setCalShowHide(!calShowHide)
  };

  useEffect(() => {
    let startDate = new Date(dateOne);
    let endDate = new Date(dateTwo);
    let timeDifference = endDate - startDate;
    if (timeDifference === 0) {
      setTotalDays(1);
    } else {
      let days = Math.abs(timeDifference / (1000 * 60 * 60 * 24));
      let finalDate = Math.floor(days);
      setTotalDays(finalDate + 1);
    }

  }, [saveDate]);

  useEffect(() => {
    setSimmerEffect(false);
  });

  const spIconStyle = {
    padding: "9px",
    width: "40px",
    height: "40px",
    borderRadius: "999px",
    backgroundColor: "#f0f9ff",
    cursor: "pointer",
  };

  const [totalAttatchmentsPrice, setTotalAttatchmentsPrice] = useState(0)

  useEffect(() => {
    const price = ((selectChild * 100) + (selectInfant * 100) + (selectPet * 100) + (AttatchmentFour * 100))
    setTotalAttatchmentsPrice(price)
  }, [selectChild, selectInfant, selectPet, AttatchmentFour])

  const [ATO, setATO] = useState(false)
  const [ATT, setATT] = useState(false)
  const [ATTH, setATTH] = useState(false)
  const [ATF, setATF] = useState(false)

  const doc = new jsPDF();

  function printPDF(){
    doc.text("Hello world!", 10, 10);
doc.save("a4.pdf");
  }

  return (
    <>
      {simmerEffect ? (
        <SinglePropertyEffect />
      ) : (
        <div className="single-property-container">
          <h3>Tractor agricultor con cuatro accesorios|retención gratuita</h3>
          <span>Bolivia</span>
          <div className="single-property">
            <div className="left-section">
              <div className="image-section">
                <img src={RoomImg} alt="" />
                <div className="TractoInfo">
                  <div>
                    <h2>Tractor</h2>
                    <h5>$ 500 per day</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-section">

              <div>
                <img
                  src={SingleOne}
                  alt="Property Image" />
                <div
                  className="text-container"
                  onMouseEnter={() => { setATO(true) }}
                  onMouseLeave={() => { setATO(false) }}>
                  <div className="Attatchment"
                    style={{ visibility: ATO && "visible", opacity: ATO && 1 }}
                  >
                    <h4>Attatchment - 1</h4>
                    <h4>$ 100 per day</h4>
                  </div>
                </div>
              </div>
              <div>
                <img
                  src={SingleTwo}
                  alt="Property Image" />
                <div
                  className="text-container"
                  onMouseEnter={() => { setATT(true) }}
                  onMouseLeave={() => { setATT(false) }}>
                  <div className="Attatchment"
                    style={{ visibility: ATT && "visible", opacity: ATT && 1 }}
                  >
                    <h4>Attatchment - 2</h4>
                    <h4>$ 200 per day</h4>
                  </div>
                </div>
              </div>
              <div>
                <img
                  src={SingleThree}
                  alt="Property Image"/>
                  <div
                  className="text-container"
                  onMouseEnter={() => { setATTH(true) }}
                  onMouseLeave={() => { setATTH(false) }}>
                  <div className="Attatchment"
                    style={{ visibility: ATTH && "visible", opacity: ATTH && 1 }}
                  >
                    <h4>Attatchment - 3</h4>
                    <h4>$ 300 per day</h4>
                  </div>
                </div>
              </div>
              <div>
                <img
                  src={SingleFour}
                  alt="Property Image"/>
                  <div
                  className="text-container"
                  onMouseEnter={() => { setATF(true) }}
                  onMouseLeave={() => { setATF(false) }}>
                  <div className="Attatchment"
                    style={{ visibility: ATF && "visible", opacity: ATF && 1 }}
                  >
                    <h4>Attatchment - 4</h4>
                    <h4>$ 350 per day</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="extra-details">
            <div className="extra-left">
              <div className="left-heading">
                <h3>Ofrecido por holatractor</h3>
                <img
                  src="https://a0.muscache.com/im/pictures/user/2c0dc3eb-3671-479b-a3c5-725ede36f6d8.jpg?im_w=240"
                  alt=""
                />
              </div>
              <div className="left-tab">
                <div className="tab">
                  {/* <BedOutlined /> */}
                  <p>2 Tractors</p>
                </div>
                <div className="tab">
                  {/* <BathtubOutlinedIcon /> */}
                  <p>4 Attachments</p>
                </div>
                {/* <div className="tab">
                  <HouseOutlinedIcon />
                  <p>Awesome Balcony</p>
                </div> */}
              </div>
              <div className="left-text">
                <div className="text-box">
                  <SingleBedOutlinedIcon />
                  <div className="text-box-item">
                    <h4>Tractor agrícola con 4 implementos</h4>
                    <p>
                      {/* Your own room in a home, plus access to shared spaces. */}
                    </p>
                  </div>
                </div>
                {/* <div className="text-box">
                  <AcUnitOutlinedIcon />
                  <div className="text-box-item">
                    <h4>Furry friends welcome</h4>
                    <p>Bring your pets along for the stay.</p>
                  </div>
                </div> */}
              </div>
              <div className="left-about">
                <h3>sobre el tractor</h3>
                <p>
                  Preparación de la tierra: los tractores se utilizan para tareas como arar, arar y nivelar el suelo para crear un semillero adecuado para la siembra.
                  Siembra: los servicios de tractores ayudan en la colocación precisa de semillas, ya sea a través de métodos de siembra tradicionales o técnicas especializadas como la siembra sin labranza o la siembra de precisión.
                  Aplicación de fertilizantes y pesticidas: Los tractores equipados con rociadores pueden aplicar fertilizantes, herbicidas y pesticidas de manera eficiente para mejorar la salud y la productividad de los cultivos.
                  Cosecha: las cosechadoras montadas en tractores o remolcadas se pueden usar para recolectar de manera eficiente cultivos como granos, frutas, verduras y forraje.
                  Henificación: los servicios de tractor ayudan a cortar, secar, rastrillar y empacar heno, proporcionando forraje de alta calidad para el ganado.
                  Riego: los tractores pueden equiparse con equipos de riego para ayudar a regar los cultivos, asegurando un crecimiento y rendimiento óptimos.
                </p>
              </div>
            </div>

            <div className="extra-right">
              <div className="extra-right-box">
                <div className="right-price">
                  <p>
                    $ 500 <span>Day</span>
                  </p>
                  <div className="star">
                    <StarOutlinedIcon style={{ color: "#0078DB" }} />
                    <p>4.87</p>
                    <span>171 reviews</span>
                  </div>
                </div>
                <div className="right-date">
                  <div className="setTime">
                    <div className="check-in-out">
                      <CalendarMonthOutlinedIcon
                        style={spIconStyle}
                        onClick={() => setCalShowHide(!calShowHide)}
                      />
                      <p>
                        <span onClick={() => setCalShowHide(!calShowHide)}>
                          {" "}
                          {startDate
                            ? format(startDate, "dd MMM yyyy ", {
                              locale: enGB,
                            })
                            : " Start "}{" "}
                        </span>{" "}
                        - {" "}
                        <span onClick={() => setCalShowHide(!calShowHide)}>
                          {" "}
                          {endDate
                            ? format(endDate, "dd MMM yyyy", { locale: enGB })
                            : " end"}
                        </span>
                      </p>
                    </div>
                    <div
                      className={`${calShowHide ? "sp-cal" : "sp-cal hide-sp-cal"
                        }`}
                    >

                      <div className="singlecal">
                        <DateRangePickerCalendar
                          startDate={startDate}
                          endDate={endDate}
                          focus={focus}
                          onStartDateChange={setStartDate}
                          onEndDateChange={setEndDate}
                          onFocusChange={handleFocusChange}
                          locale={enGB}
                        />
                        <div className="singlebtn">
                          <button onClick={saveDate}>SAVE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="guests">
                    <p>
                      {selectPerson === 1
                        ? selectPerson + " Tractor "
                        : selectPerson + " Tractors "}
                    </p>
                    <ArrowDropDownOutlinedIcon
                      onClick={() => setGuestToggle(!guestToggle)}
                      style={{ cursor: "pointer" }}
                    />
                    <div
                      className={`${guestToggle ? "select-guest" : "select-guest hide-guest"
                        }`}
                    >
                      <ul className="ul-list-one">
                        <li>
                          Tractors
                        </li>
                        <div className="inc-desc">
                          <div
                            className="desc"
                            onClick={() =>
                              selectPerson === 0
                                ? setSelectPerson(0)
                                : setSelectPerson(selectPerson - 1)
                            }
                          >
                            <i className="fa-solid fa-minus"></i>
                          </div>
                          <div className="number">{selectPerson}</div>
                          <div
                            className="inc"
                            onClick={() => setSelectPerson(selectPerson + 1)}
                          >
                            <i className="fa-solid fa-plus"></i>
                          </div>
                        </div>
                      </ul>
                      <ul className="ul-list-two">
                        <li>
                          Attatchment - 1
                        </li>
                        <div className="inc-desc">
                          <div
                            className="desc"
                            onClick={() =>
                              selectChild === 0
                                ? setSelectChild(0)
                                : setSelectChild(selectChild - 1)
                            }
                          >
                            <i className="fa-solid fa-minus"></i>
                          </div>
                          <div className="number">{selectChild}</div>
                          <div
                            className="inc"
                            onClick={() => setSelectChild(selectChild + 1)}
                          >
                            <i className="fa-solid fa-plus"></i>
                          </div>
                        </div>
                      </ul>
                      <ul className="ul-list-three">
                        <li>
                          Attatchment - 2
                        </li>
                        <div className="inc-desc">
                          <div
                            className="desc"
                            onClick={() =>
                              selectInfant === 0
                                ? setSelectInfant(0)
                                : setSelectInfant(selectInfant - 1)
                            }
                          >
                            <i className="fa-solid fa-minus"></i>
                          </div>
                          <div className="number">{selectInfant}</div>
                          <div
                            className="inc"
                            onClick={() => setSelectInfant(selectInfant + 1)}
                          >
                            <i className="fa-solid fa-plus"></i>
                          </div>
                        </div>
                      </ul>
                      <ul className="ul-list-four">
                        <li>
                          Attatchment - 3
                        </li>
                        <div className="inc-desc">
                          <div
                            className="desc"
                            onClick={() =>
                              selectPet === 0
                                ? setSelectPet(0)
                                : setSelectPet(selectPet - 1)
                            }
                          >
                            <i className="fa-solid fa-minus"></i>
                          </div>
                          <div className="number">{selectPet}</div>
                          <div
                            className="inc"
                            onClick={() => setSelectPet(selectPet + 1)}
                          >
                            <i className="fa-solid fa-plus"></i>
                          </div>
                        </div>
                      </ul>

                      <ul className="ul-list-four">
                        <li>
                          Attatchment - 4
                        </li>
                        <div className="inc-desc">
                          <div
                            className="desc"
                            onClick={() =>
                              AttatchmentFour === 0
                                ? setAttatchmentFour(0)
                                : setAttatchmentFour(AttatchmentFour - 1)
                            }
                          >
                            <i className="fa-solid fa-minus"></i>
                          </div>
                          <div className="number">{AttatchmentFour}</div>
                          <div
                            className="inc"
                            onClick={() => setAttatchmentFour(AttatchmentFour + 1)}
                          >
                            <i className="fa-solid fa-plus"></i>
                          </div>
                        </div>
                      </ul>

                      <p className="bottom-para">
                        ...............
                      </p>
                      <div className="exit">
                        <p onClick={() => setGuestToggle(!guestToggle)}>
                          Close
                        </p>
                      </div>
                    </div>
                  </div>
                  <button className="sp-button" onClick={()=>{ printPDF() }}>Reservar ahora</button>
                  <span className="d-charge">Aún no te cobrarán</span>
                </div>
                <div className="price-cal">
                  <div className="price-one">
                    <p>
                      $ {roomPrice} * {totalDays} day
                    </p>
                    <p>{roomPrice * totalDays * selectPerson}</p>
                  </div>
                  <div className="price-one">
                    <p>Archivos adjuntos</p>
                    <p>${totalAttatchmentsPrice * totalDays}
                    </p>
                  </div>
                  <div className="price-one">
                    <p>Cargo por servicio</p>
                    <p>${cleaningFee}</p>
                  </div>
                  <div className="price-total">
                    <p>Precio total</p>
                    <p>
                      $
                      {
                        roomPrice * totalDays * selectPerson + cleaningFee + (totalAttatchmentsPrice * totalDays)
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="map">
            <h3>donde estarás</h3>
            <p>Bolivia</p>
            <iframe
              // src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d56053.10572697137!2d77.19123018808592!3d28.590202210298823!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1686136473844!5m2!1sen!2sin"
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d77075.11367471434!2d-68.1192938!3d-16.5001016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1686136473844!5m2!1sen!2sin"
              width="90%"
              height="350"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleProperties;
