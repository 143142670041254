import React from 'react';
import "./forbuilders.scss";
import BuilderLogo from "../../../../utils/d_hp_ppf_xl.jpg"
import { Link } from 'react-router-dom';

const ForComunidad = () => {
    return (
        <div className='forbuilder-container'>
            <div className="builder-box-one">
                {/* <p>¿Cómo Trabajamos?</p> */}
                <p>¿Cómo unirse a una comunidad?</p>
                {/* <p> Únete A La Comunidad</p> */}
                <p> ¿Cómo unirse como propietario de la comunidad?</p>
                {/* <p> ¿Cómo Ser Parte?</p> */}
                <p> ¿Cómo ayudar a los agricultores como propietarios de la comunidad?</p>
                <p> ¿Cómo obtener beneficios de nuestra comunidad?</p>
                {/* <div className="box-one-bottom">
                    <p>servicios especiales</p>
                    <p>Contacta con nosotros</p>
                    <span>+591 77613811</span>
                </div> */}
            </div>
            <div className="builder-box-two">
                <p>Características de la comunidad</p>
                <div className="box-two-text">
                    <Link to={'/comunidad/post-requirement-of-tractor'} style={{textDecoration: 'none'}}>
                        <p>Poste requisito de tractor</p>
                    </Link>
                    <Link to={'/comunidad/beneficio-tecnológico-para-la-agricultura'} style={{textDecoration: 'none'}}>
                    <p>Beneficio tecnológico para la agricultura</p>
                    </Link>
                    <Link to={'/comunidad/reuniones-communitrates'} style={{textDecoration: 'none'}}>
                    <p>Reuniones comunitarias</p>
                    </Link>
                    <Link to={'/comunidad/consejos-de-cultivo'} style={{textDecoration: 'none'}}>
                    <p>Consejos de cultivo</p>
                    </Link>
                </div>
            </div>
            <div className="builder-box-three">
                <img src={BuilderLogo} alt="Builder Image" />
            </div>

            <div className="builder-box-four">

                <a href="#" target="_blank" rel="noopener noreferrer">
                    <div className="button1 button">Join us</div>
                </a>

                <a href="#" target="_blank" rel="noopener noreferrer">
                    <div className="button2 button">Community Owner</div>
                </a>

                <a href="#" target="_blank" rel="noopener noreferrer">
                    <div className="button3 button">Partners</div>
                </a>
            </div>
        </div>
    )
}

export default ForComunidad