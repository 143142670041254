import PayOnCreadit from '../../assets/Alquilar.png';
import PersonalLoan from '../../assets/comprar tractor nuevo.png'
import QuickCash from '../../assets/Compro tractor antiguo.png'
import RentNow from '../../assets/Alquile ahora pague después.png';
import RentAggrement from '../../assets/Vende tu tractor.png'
import HomeLoan from '../../assets/mapeo de tierras.png'
import HomeInterior from '../../assets/Gestión de flotas.png'
import Packers from '../../assets/Préstamo de tractores.png';
import HousingPremium from '../../assets/Reparar tractores.png'
import PropertyManagement from '../../assets/Operador de tractor.png'
import FixedDeposit from '../../assets/Cambio climático.png'


const EdgeData = [
    {
        id:1,
        image : PayOnCreadit,
        title : "Alquilar"
    },
    {
        id:2,
        image : PersonalLoan,
        title : "comprar tractor nuevo"
    },
    {
        id:3,
        image : QuickCash,
        title : "Compro tractor antiguo"
    },
    {
        id:4,
        image : RentNow,
        title : "Alquile ahora pague después"
    },
    {
        id:5,
        image : RentAggrement,
        title : "Vende tu tractor"
    },
    {
        id:6,
        image : HomeLoan,
        title : "Mapeo de tierras"
    },
    {
        id:7,
        image : HomeInterior,
        title : "Gestión de flotas"
    },
    {
        id:8,
        image : Packers,
        title : "Préstamo de tractores"
    },
    {
        id:9,
        image : HousingPremium,
        title : "Reparar tractores"
    },
    {
        id:10,
        image : PropertyManagement,
        title : "Operador de tractor"
    },
    {
        id:11,
        image : FixedDeposit,
        title : "Cambio climático"
    }
]

export default EdgeData;