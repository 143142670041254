import { createSlice } from "@reduxjs/toolkit";

export const userLogInComponentShow = createSlice({
  name: "UserComponentShow",

  initialState: false,

  reducers: {

    updateUserComponent: (state) => {
      return !state; // Toggle the state between true and false
    },

  },
  
});

export const { updateUserComponent } = userLogInComponentShow.actions;

export default userLogInComponentShow.reducer;
