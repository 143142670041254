import React, { useEffect } from 'react'
import PMMP from '../../palmComponents/HomePage/PMMP'
import MMMp from '../../palmComponents/HomePage/MMMp'
import PalmMills from '../../palmComponents/HomePage/PalmMills'
import Contact from '../../palmComponents/HomePage/Contact'
// import TrustedPartners from '../../components/HomePage/TrustedPartners'
import Capabilities from '../../palmComponents/HomePage/Capabilities'
// import PalmTreeProcess from '../../components/PalmTreeProcess/PalmTreeProcess'
import { useSelector } from 'react-redux'

const HomePage = () => {

    const languageOption = useSelector(state=>state.language.language)

    return (
        <div className='w-full'>

            <div
                className='w-full min-h-[100vh] relative before:absolute before:right-0 before:top-0 before:h-full before:w-[80%] before:bg-red-50 before:z-[-1] before:rounded-bl-[120px] flex items-center justify-center'>

                <div className='w-[90%] 1000px:w-[80%] mx-auto h-full flex items-center justify-center flex-col-reverse gap-[20px] 900px:flex-row'>



                    <div className='w-full 900px:w-[50%] flex items-center justify-center flex-col gap-[16px]'>

                        <div className='text-[#028A86] font-bold text-[36px] 500px:text-[42px] w-full'>
                            {
                                languageOption === 'English' ?
                                'Harvesting Tomorrow, Responsibly Today: Your Smart Choice for Palm Recycling!'
                                :
                                'Cosechando mañana, responsablemente hoy: ¡su elección inteligente para el reciclaje de palma!'
                            }
                        </div>

                        <div className='text-[#4D4D4D] text-[16px] 500px:text-[18px] 800px:text-[19px] 1000px:text-[23px] 1500px:text-[24px] font-bold w-full'>
                        {
                                languageOption === 'English' ?
                                'Our IoT Smart Device Redefines Palm Recollection by Optimizing Routes, Conserving Forests, and Ensuring Fair Income. Explore Smart Routes, Maximize Productivity, and Preserve Nature with Us!'
                                :
                                'Nuestro dispositivo inteligente IoT redefine la recolección de palmeras al optimizar rutas, conservar bosques y garantizar ingresos justos. ¡Explore rutas inteligentes, maximice la productividad y preserve la naturaleza con nosotros!'
                            }
                        </div>

                    </div>

                    <div className='w-full 900px:w-[50%] mt-[30px] 900px:mt-0'>
                        <img
                            src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/palm/palm_banner.jpg"
                            alt=""
                            className='w-full object-cover rounded-[10px]' />
                    </div>

                </div>

            </div>

            <Capabilities />

            <PMMP />

            <MMMp />

            <PalmMills />

            <Contact />

            {/* <TrustedPartners /> */}

            {/* <PalmTreeProcess /> */}

        </div>
    )
}

export default HomePage