import React from 'react'
import { useSelector } from 'react-redux'

const AcaiComponentOne = () => {

    const languageOption = useSelector(state => state.language.language)

    return (
        <div className='w-full'>

            <div
                className='w-full min-h-[100vh] relative flex items-center justify-center'>

                    <img 
                    src="https://wallpapercave.com/wp/wp11640291.jpg" 
                    alt=""
                    className='absolute right-0 top-0 h-full w-[80%] object-cover z-[-1] rounded-bl-[120px]' />

                <div className='w-[90%] 1000px:w-[80%] mx-auto h-full flex items-center justify-center flex-col-reverse gap-[20px] 900px:flex-row'>



                    <div className='w-full flex items-center justify-center flex-col gap-[16px]'>

                        <div className='text-[#028A86] font-bold text-[36px] 500px:text-[42px] 1000px:text-[52px] w-full'>
                            {
                                languageOption === 'English' ?
                                    'Reviving the Land, Sustaining the Future: Our Regenerative Commitment!'
                                    :
                                    'Revivir la tierra, sostener el futuro: nuestro compromiso regenerativo!'
                            }
                        </div>

                    </div>

                    {/* <div className='w-full 900px:w-[50%] mt-[30px] 900px:mt-0'>
                        <img
                            src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/palm/palm_banner.jpg"
                            alt=""
                            className='w-full object-cover rounded-[10px]' />
                    </div> */}

                </div>

            </div>

        </div>
    )
}

export default AcaiComponentOne