import React from 'react'

const AcaiComponentSix = () => {
  return (
    <div 
    className='w-[90%] 1000px:w-[80%] mx-auto py-[40px] flex items-center justify-center gap-[20px] flex-col 900px:flex-row'>

        <div className='w-full 900px:w-1/2 flex flex-col gap-[20px]'>

            <p className='text-[22px] 900px:text-[26px] font-bold'>
            Closed Loop Management
            </p>

            <p className='text-[18px] text-gray-600'>
            To ensure continuous improvement and better yields, we have introduced a closed loop system to allow ongoing tracking and improvements at the touch of a mobile device.
            </p>

            <p className='text-[18px] text-gray-600'>
            With all of your key data touch points aligning into a single source of truth, you can confidently and remotely manage your plantation.
            </p>

        </div>

        <img 
        src="https://absi.global/wp-content/uploads/2020/07/durian-closed-loop-management.jpg" 
        alt=""
        className='w-full 900px:w-1/2 object-cover rounded-[20px]' />

    </div>
  )
}

export default AcaiComponentSix