import React from 'react'
import { useSelector } from 'react-redux'

const RegenerativeAgricultureFour = () => {

    const languageOption = useSelector(state => state.language.language)

    const contentArray = languageOption === 'English' ? [
        {
            imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/palm/partners.png",
            heading: "Skill-sharing across Community ",
            desc: "We organize workshops within the community where individuals can learn the best practices for growing and harvesting palm, almond, and acai plants through holatractor services inside the postulation."
        },
        {
            imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/palm/mapins.png",
            heading: "Sustainable harvesting",
            desc: "Sustainable harvesting practices are crucial to ensure the long-term viability of these resources. Overharvesting can lead to environmental degradation and loss of biodiversity. We work to promote sustainable harvesting techniques, which include methods like selective harvesting, protecting young plants, and reforestation efforts."
        },
        {
            imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/palm/financial-market.png",
            heading: "Access to market",
            desc: "We establish partnerships with vendors who work directly with these communities. Vendors could include local cooperatives, fair trade organizations, or sustainable agriculture initiatives. Collaborate with these vendors to bridge the gap between the communities and the market."
        },
        {
            imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/palm/online-community.png",
            heading: "Technology and innovation",
            desc: "Our service or technology platform that aims to help palm, almonds, and acai collectors increase their productivity and growth as community members.Leveraging technology in re-collection can indeed lead to significant improvements in productivity, efficiency, and sustainability."
        },
        {
            imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/palm/financial-market.png",
            heading: "Access to market",
            desc: "We establish partnerships with vendors who work directly with these communities. Vendors could include local cooperatives, fair trade organizations, or sustainable agriculture initiatives. Collaborate with these vendors to bridge the gap between the communities and the market."
        },
        {
            imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/palm/online-community.png",
            heading: "Technology and innovation",
            desc: "Our service or technology platform that aims to help palm, almonds, and acai collectors increase their productivity and growth as community members.Leveraging technology in re-collection can indeed lead to significant improvements in productivity, efficiency, and sustainability."
        },
    ]
        :
        [
            {
                imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/palm/partners.png",
                heading: "Compartir habilidades en toda la comunidad",
                desc: "Organizamos talleres dentro de la comunidad donde las personas pueden aprender las mejores prácticas para cultivar y cosechar plantas de palma, almendro y acai a través de los servicios de holatractor dentro de la postulación."
            },
            {
                imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/palm/mapins.png",
                heading: "Cosecha sostenible",
                desc: "Las prácticas de recolección sostenibles son cruciales para garantizar la viabilidad a largo plazo de estos recursos. La sobreexplotación puede conducir a la degradación ambiental y la pérdida de biodiversidad. Trabajamos para promover técnicas de cosecha sostenibles, que incluyen métodos como la cosecha selectiva, la protección de plantas jóvenes y esfuerzos de reforestación.."
            },
            {
                imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/palm/financial-market.png",
                heading: "Acceso al mercado",
                desc: "Establecemos asociaciones con proveedores que trabajan directamente con estas comunidades. Los proveedores podrían incluir cooperativas locales, organizaciones de comercio justo o iniciativas de agricultura sostenible. Colaborar con estos proveedores para cerrar la brecha entre las comunidades y el mercado."
            },
            {
                imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/palm/online-community.png",
                heading: "Tecnología e innovación",
                desc: "Nuestro servicio o plataforma tecnológica que tiene como objetivo ayudar a los recolectores de palma, almendras y acai a aumentar su productividad y crecimiento como miembros de la comunidad. Aprovechar la tecnología en la recolección puede conducir a mejoras significativas en la productividad, la eficiencia y la sostenibilidad."
            },
            {
                imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/palm/financial-market.png",
                heading: "Acceso al mercado",
                desc: "Establecemos asociaciones con proveedores que trabajan directamente con estas comunidades. Los proveedores podrían incluir cooperativas locales, organizaciones de comercio justo o iniciativas de agricultura sostenible. Colaborar con estos proveedores para cerrar la brecha entre las comunidades y el mercado."
            },
            {
                imageLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/palm/online-community.png",
                heading: "Tecnología e innovación",
                desc: "Nuestro servicio o plataforma tecnológica que tiene como objetivo ayudar a los recolectores de palma, almendras y acai a aumentar su productividad y crecimiento como miembros de la comunidad. Aprovechar la tecnología en la recolección puede conducir a mejoras significativas en la productividad, la eficiencia y la sostenibilidad."
            },
        ]

  return (
    <div 
    className='w-[90%] 1000px:w-[80%] mx-auto flex flex-col items-center justify-center gap-[30px] py-[40px]'>

        <div className='w-[200px] h-[4px] rounded-full bg-green-600' />

        <p className='text-[26px] font-bold 900px:text-[42px] 500px:text-[36px]'>
            Lorem ipsum dolor sit.
        </p>

        <p className='text-[22px] text-gray-600 text-center'>
            Lorem, ipsum dolor.
        </p>

        <div className='w-full grid grid-cols-1 500px:grid-cols-2 1000px:grid-cols-3 gap-8'>

            {
                contentArray.map((content, index) => {
                    return (
                        <div
                            key={index}
                            className='flex flex-col items-center gap-[20px] cursor-pointer'>

                            <img
                                src={content.imageLink}
                                alt=""
                                className='w-[280px]' />

                            <p className='font-bold text-[18px]'>
                                {content.heading}
                            </p>

                            <p className='text-center'>
                                {content.desc}
                            </p>

                        </div>
                    )
                })
            }

        </div>

        <div className='w-[200px] h-[4px] rounded-full bg-green-600' />

    </div>
  )
}

export default RegenerativeAgricultureFour