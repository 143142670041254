import React from 'react'

const FirstComponent = () => {
    return (
        <div className='FirstComponent'>

            <div className="left">

                <div className="heading">¿Cómo incorporarse como propietario de un tractor?</div>

                <div className="sub-heading">Plataforma propietario Holatractor</div>

                <div className="para1">
                Busque plataformas en línea o aplicaciones móviles diseñadas específicamente para conectar proveedores de servicios, como propietarios de tractores, con clientes.
                </div>

                <div className="para2">
                Además del arado y la labranza básicos, considere ofrecer servicios especializados como nivelación de tierras, preparación de tierras, instalación de sistemas de riego, recolección de cultivos o remoción de árboles. La diversificación de sus servicios puede satisfacer una gama más amplia de necesidades agrícolas y de desarrollo territorial.
                </div>

            </div>

            <div className="right">

                <div className="button">
                    <span>Aplicar</span>
                </div>

                <div className="buttom">

                    <div className="Form">

                        <div className="formLabel">Paso uno:</div>
                        <div className="formValue">Crear un perfil en línea</div>

                    </div>

                    <div className="Form">

                        <div className="formLabel">Segundo paso:</div>
                        <div className="formValue">Verifique sus credenciales</div>

                    </div>

                    <div className="Form">

                        <div className="formLabel">Paso tres:</div>
                        <div className="formValue">sube tus servicios y establece tu precio</div>

                    </div>

                    <div className="Form">

                        <div className="formLabel">Paso cuatro:</div>
                        <div className="formValue">Promocione sus servicios localmente</div>

                    </div>

                    <div className="Form">

                        <div className="formLabel">Paso cinco:</div>
                        <div className="formValue">Consigue la reserva y gana</div>

                    </div>

                </div>
            </div>

        </div>
    )
}

export default FirstComponent