import React from 'react'
import { useSelector } from 'react-redux'

const CommunityComponentOne = () => {

    const languageOption = useSelector(state => state.language.language)

    return (
        <div className='w-full'>

            <div
                className='w-full min-h-[100vh] relative before:absolute before:right-0 before:top-0 before:h-full before:w-[80%] before:bg-red-50 before:z-[-1] before:rounded-bl-[120px] flex items-center justify-center'>

                <div className='w-[90%] 1000px:w-[80%] mx-auto h-full flex items-center justify-center flex-col-reverse gap-[20px] 900px:flex-row'>



                    <div className='w-full 900px:w-[50%] flex items-center justify-center flex-col gap-[16px]'>

                        <div className='text-[#028A86] font-bold text-[36px] 500px:text-[42px] 1000px:text-[52px] w-full'>
                            {
                                languageOption === 'English' ?
                                    'Community engagement in such activities can have various social, economic, and environmental benefits.'
                                    :
                                    'La participación de la comunidad en tales actividades puede tener diversos beneficios sociales, económicos y ambientales.'
                            }
                        </div>

                    </div>

                    <div className='w-full 900px:w-[50%] mt-[30px] 900px:mt-0'>
                        <img
                            src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/palm/community.jfif"
                            alt=""
                            className='w-full object-cover rounded-[10px]' />
                    </div>

                </div>

            </div>

        </div>
    )
}

export default CommunityComponentOne