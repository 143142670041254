import React from 'react'
import { useSelector } from 'react-redux'

const CommunityComponentThree = () => {

  const languageOption = useSelector(state => state.language.language)

  return (
    <div className='w-[90%] 1000px:w-[80%] mx-auto py-[40px] border-t-2 border-gray-200'>

      <p className='text-[20px] 900px:text-[24px] text-gray-600 leading-[30px] 900px:leading-[36px]'>
        {
          languageOption === 'English' ?
          'Palm trees are abundant in the rainforest and provide various resources, such as palm oil and heart of palm, used in culinary and industrial applications. Almonds could refer to different types of nuts found in the rainforest, although the almond tree (Prunus dulcis) is not native to the Amazon. Acai berries, on the other hand, are small, dark purple fruits native to the Amazon rainforest in Brazil.'
          :
          'Las palmeras abundan en la selva tropical y proporcionan diversos recursos, como el aceite de palma y el palmito, que se utilizan en aplicaciones culinarias e industriales. Las almendras podrían referirse a distintos tipos de frutos secos que se encuentran en la selva tropical, aunque el almendro (Prunus dulcis) no es originario del Amazonas. Las bayas de acai, por otro lado, son frutos pequeños de color púrpura oscuro originarios de la selva amazónica de Brasil.'
        }
      </p>

    </div>
  )
}

export default CommunityComponentThree