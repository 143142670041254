import React from 'react'
import { useSelector } from 'react-redux'

const Contact = () => {

    const languageOption = useSelector(state => state.language.language)

    return (
        <div className='w-full py-[40px] bg-red-50'>

            <div 
            className='w-[90%] 900px:w-[80%] mx-auto flex items-center justify-center flex-col 900px:flex-row gap-[20px] 900px:gap-0'>

                <div className='w-full 900px:w-[50%] flex flex-col items-center justify-center gap-[20px]'>

                    <p 
                    className='text-[40px] 500px:text-[45px] 800px:text-[48px] 1400px:text-[52px] 1500px:text-[60px] text-[#000000] leading-[42px] 500px:leading-[47px] 800px:leading-[50px] 1400px:leading-[54px] 1500px:leading-[62px]'>
                       Let's connect
                    </p>

                    <p className='text-[16px] 500px:text-[18px] 800px:text-[20px] 1400px:text-[21px] 1500px:text-[22px] text-[#000000]'>
                        Complete the form to get in touch with one of our experts to see our products in action. We’ll show you how you can Holatractor to drive productivity and yield within your business.
                    </p>

                </div>

                <div className='w-full 900px:w-[50%] flex items-center justify-center'>

                    <div className='flex flex-col items-center justify-center bg-white gap-[16px] p-[20px]'>

                        <p className='text-[#000000] text-[32px] font-bold'>
                            Contact Us
                        </p>

                        <div className='w-[140px] h-[7px] rounded-full bg-[#028a86]' />

                        <div className='grid grid-cols-1 600px:grid-cols-2 gap-4'>

                            <div className='border border-gray-200 p-[8px]'>

                                <input 
                                type="text"
                                placeholder={languageOption === 'English' ? 'First Name' : 'Nombre de pila'}
                                className='outline-none border-none' />

                            </div>

                            <div className='border border-gray-200 p-[8px]'>

                                <input 
                                type="text"
                                placeholder={languageOption === 'English' ? 'Last Name' : 'Apellido'}
                                className='outline-none border-none' />

                            </div>

                            <div className='border border-gray-200 p-[8px]'>

                                <input 
                                type="text"
                                placeholder={languageOption === 'English' ? 'Company' : 'Compañía'}
                                className='outline-none border-none' />

                            </div>

                            <div className='border border-gray-200 p-[8px]'>

                                <input 
                                type="text"
                                placeholder={languageOption === 'English' ? 'Job title' : 'Título profesional'}
                                className='outline-none border-none' />

                            </div>

                            <div className='border border-gray-200 p-[8px]'>

                                <input 
                                type="email"
                                placeholder={languageOption === 'English' ? 'Work email' : 'Correo electrónico del trabajo'}
                                className='outline-none border-none' />

                            </div>

                            <div className='border border-gray-200 p-[8px]'>

                                <input 
                                type="text"
                                placeholder={languageOption === 'English' ? 'Phone number' : 'Número de teléfono'}
                                className='outline-none border-none' />

                            </div>

                            <div className='border border-gray-200 p-[8px]'>

                                <input 
                                type="text"
                                placeholder={languageOption === 'English' ? 'Country' : 'País'}
                                className='outline-none border-none' />

                            </div>

                        </div>

                        <button className='w-full py-[10px] bg-[#028a86] text-white font-bold'>
                        {languageOption === 'English' ? 'Submit' : 'Entregar'}
                        </button>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default Contact