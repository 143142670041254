import React from "react";
import "./accountHover.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateUserComponent } from "../../../../redux/userLogInComponentShow";

const AccountHover = () => {

  const navigate = useNavigate()

  const dispatch = useDispatch()

  return (
    <div className="account-hover">
      <div className="user-hover-btn">
        <button 
        // onClick={()=> navigate("/login")}
        onClick={()=>{dispatch(updateUserComponent())}}
        >Acceso</button>
        <span>/</span>
        <button onClick={()=> navigate("/register")}>Registro</button>
      </div>
      <div className="my-activity">
        <p>Mi actividad</p>
        <div className="activity-list">
          <p> Búsqueda reciente</p>
          <p> visto recientemente</p>
          <p>Preseleccionado</p>
          <p>Contactado</p>
        </div>
      </div>
    </div>
  );
};

export default AccountHover;
