import React from 'react';
import "./forbuilders.scss";
import BuilderLogo from "../../../../utils/d_hp_ppf_xl.jpg"
import { Link } from 'react-router-dom';

const ForAgent = () => {
    return (
        <div className='forbuilder-container'>
            <div className="builder-box-one">
                {/* <p>¿Cómo Trabajamos?</p> */}
                <p>Cómo unirse con nosotros como agente?</p>
                {/* <p> Únete A La Comunidad</p> */}
                <p>¿Cómo unirse como operador?</p>
                {/* <p> ¿Cómo Ser Parte?</p> */}
                <p>¿Cómo crecemos jóvenes emprendedores?</p>
                <p>¿Cómo ganar con nosotros?</p>
                {/* <div className="box-one-bottom">
                    <p>servicios especiales</p>
                    <p>Contacta con nosotros</p>
                    <span>+591 77613811</span>
                </div> */}
            </div>
            <div className="builder-box-two">
                <p>ofertas</p>
                <div className="box-two-text">
                    <Link to={'/agente/servicios-de-soporte-de-agentes'} style={{ textDecoration: 'none' }}>
                        <p>
                            Servicios de soporte de agentes
                        </p>
                    </Link>
                    <Link to={'/agente/reparación-de-tractores'} style={{ textDecoration: 'none' }}>
                        <p>
                            Reparación de tractores
                        </p>
                    </Link>
                    <Link to={'/agente/mesa-de-ayuda'} style={{ textDecoration: 'none' }}>
                        <p>
                            Mesa de ayuda
                        </p>
                    </Link>
                    <Link to={'/agente/servicios-de-reparacion-de-escalones'} style={{ textDecoration: 'none' }}>
                        <p>
                            Servicios de reparación de escalones
                        </p>
                    </Link>
                </div>
            </div>
            <div className="builder-box-three">
                <img
                    src={"https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/women_tractor.webp"}
                    alt="Builder Image" />
            </div>
            <div className="builder-box-four">

                <a href="#" target="_blank" rel="noopener noreferrer">
                    <div className="button1 button">Agent</div>
                </a>

                <a href="#" target="_blank" rel="noopener noreferrer">
                    <div className="button2 button">Operator</div>
                </a>
            </div>
        </div>
    )
}

export default ForAgent