import React from 'react'
import './MiniTractorPage.scss'
import { motion } from 'framer-motion'
import BookingStepper from './BookingStepper'

const MiniTractorPage = () => {
    return (
        <div className='MiniTractorPage'>

            <div className="banner">

                <img
                    src="https://t3.ftcdn.net/jpg/06/42/34/86/360_F_642348606_RY0LNG7axZA68QZbNRD1QDi5pahjbvzu.jpg"
                    alt="mini tractor banner" />

                    <motion.div
                    initial={{
                        x: -100,
                        opacity: 0
                      }}
                      whileInView={{
                        x: 0,
                        opacity: 1
                      }}
                      transition={{
                        duration: 1
                      }}
                      className='page-heading'>
                        Two wheeler tractors
                    </motion.div>

            </div>

            <BookingStepper />

        </div>
    )
}

export default MiniTractorPage