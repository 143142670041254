import React from 'react'
import Dash from '../../../assets/demoImages/minus.png'

const SeconsComponent = () => {
  return (
    <div className='SeconsComponent'>

      <div className="left">

        <div className="heading">
        Beneficios para propietarios de tractores
        </div>

        <div className="para1">
        Incluir sus servicios de tractores en plataformas en línea holatractor o mercados agrícolas puede ofrecer varios beneficios a los propietarios de tractores.
        </div>

        <div className="keypoints">

          <div className="keypoint">
            <img src={Dash} alt="" />
            <span>Mayor visibilidad</span>
          </div>

          <div className="keypoint">
            <img src={Dash} alt="" />
            <span>Conveniencia para los clientes</span>
          </div>

          <div className="keypoint">
            <img src={Dash} alt="" />
            <span>Contratar operador holatractor</span>
          </div>
          
          <div className="keypoint">
            <img src={Dash} alt="" />
            <span>Realice un seguimiento de su tractor con nuestro dispositivo integrado IOT</span>
          </div>

          <div className="keypoint">
            <img src={Dash} alt="" />
            <span>Mayores reservas</span>
          </div>

          <div className="keypoint">
            <img src={Dash} alt="" />
            <span>Credibilidad y confianza</span>
          </div>


          <div className="keypoint">
            <img src={Dash} alt="" />
            <span>Marketing y publicidad</span>
          </div>

          <div className="keypoint">
            <img src={Dash} alt="" />
            <span>Comodidad de pago</span>
          </div>

          <div className="keypoint">
            <img src={Dash} alt="" />
            <span>Atención al agente del cliente</span>
          </div>

        </div>

        <div className="para1">
        Todas las funciones a las que puede acceder en su propio panel web y aplicación móvil para monitorear los servicios.Con los servicios de holatractor no solo puede proporcionar los servicios del tractor sino que también puede apoyar a la comunidad agrícola para aumentar su productividad.
        </div>

        <div className="para1">
        
        </div>

      </div>

      <div className="right">

        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/grow.jfif" alt="" />

      </div>

    </div>
  )
}

export default SeconsComponent