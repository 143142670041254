import React, { useState } from 'react'
import demoImage from '../../../assets/farmer_tract.jpg'
import demoLogo from '../../../assets/partnerBrandImage.jpg'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const PartnersOfFarmers = () => {

    const boxesArray = [
        {
            id: 1,
            partnerLogo: demoLogo,
            p: "Reserva de tractor rentable",
            companyName: 'Hola tractors',
            rightImage: demoImage
        },
        {
            id: 2,
            partnerLogo: demoLogo,
            p: "Proceso de reserva simplificado",
            companyName: 'Hola tractors',
            rightImage: demoImage
        },
        {
            id: 3,
            partnerLogo: demoLogo,
            p: "Tractores de dos ruedas",
            companyName: 'Hola tractors',
            rightImage: demoImage
        },
    ]

    const mediaQuery = window.matchMedia(`(max-width: 700px)`);

    const [query, setQuery] = useState(mediaQuery.matches);

    window.onresize = () => {
        setQuery(mediaQuery.matches);
    };

  return (

    <div className='PartnersOfFarmers'>

        <div className="mainHeading">
        Soluciones de tractores asequibles para pequeños agricultores
        </div>

        <div className="subHeading">
        Reserva de tractores asequible y una plataforma para el alquiler de tractores que le permitirán cultivar el éxito.
        </div>

        <div className="container">

            {
                boxesArray.map((box)=>{
                    return (
                        <div key={box.id} className='boxContainer'>

                            <div className="left">

                                {/* <img src={box.partnerLogo} alt="" /> */}

                                <p1>{box.p}</p1>
                                <div className='companyName'>{box.companyName}</div>

                                <button>
                                   <p>Saber más</p> 
                                </button>

                            </div>

                            <div className="right">
                                <img src={box.rightImage} alt="" />
                            </div>

                        </div>
                    )
                })
            }

        </div>

    </div>

  )

}

export default PartnersOfFarmers