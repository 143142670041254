import React from 'react'
import './PrivacyPolicy.scss'

const PrivacyPolicy = () => {
    return (
        <div className='PrivacyPolicy'>

            <div className="Container">

                <div className='HOne'>política de privacidad</div>
                <p>
                    Holatractor creó la aplicación Holatractor como una aplicación gratuita. Holatractor proporciona este SERVICIO sin costo alguno y está diseñado para usarse tal cual.
                </p>
                <p>
                    Esta página se utiliza para informar a los visitantes sobre nuestras políticas con la recopilación, el uso y la divulgación de información personal si alguien decide utilizar nuestro Servicio.
                </p>
                <p>
                    Si elige utilizar nuestro Servicio, acepta la recopilación y el uso de información en relación con esta política. La información personal que recopilamos se utiliza para proporcionar y mejorar el Servicio. No usaremos ni compartiremos su información con nadie excepto como se describe en esta Política de privacidad.
                </p>
                <p>
                    Los términos utilizados en esta Política de privacidad tienen los mismos significados que en nuestros Términos y condiciones, a los que se puede acceder en Holatractor a menos que se defina lo contrario en esta Política de privacidad.
                </p>
                <p><div className='HTwo'>Recopilación y uso de información</div></p>
                <p>
                    Para una mejor experiencia, mientras utiliza nuestro Servicio, es posible que le solicitemos que nos proporcione cierta información de identificación personal. La información que solicitamos será retenida por nosotros y utilizada como se describe en esta política de privacidad.
                </p>
                <div>
                    <p>
                        La aplicación utiliza servicios de terceros que pueden recopilar información utilizada para identificarlo.
                    </p>
                    <p>
                        Enlace a la política de privacidad de los proveedores de servicios de terceros utilizados por la aplicación
                    </p>
                    <ul>
                        <li>
                            <a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Servicios de Google Play</a></li>
                        <li><a href="https://firebase.google.com/policies/analytics" target="_blank" rel="noopener noreferrer">Google Analytics para Firebase</a></li>
                        <li><a href="https://www.facebook.com/about/privacy/update/printable" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                    </ul>
                </div>
                <p>
                    <div className='HTwo'>
                        Dato de registro
                    </div>
                </p>
                <p>
                    Queremos informarle que cada vez que utiliza nuestro Servicio, en caso de un error en la aplicación, recopilamos datos e información (a través de productos de terceros) en su teléfono llamados Datos de registro. Estos datos de registro pueden incluir información como la dirección del Protocolo de Internet ("IP") de su dispositivo, el nombre del dispositivo, la versión del sistema operativo, la configuración de la aplicación cuando utiliza nuestro Servicio, la hora y la fecha de su uso del Servicio y otras estadísticas. .
                </p>
                <p>
                    <div className='HTwo'>
                        Galletas
                    </div>
                </p>
                <p>
                    Las cookies son archivos con una pequeña cantidad de datos que se utilizan comúnmente como identificadores únicos anónimos. Estos se envían a su navegador desde los sitios web que visita y se almacenan en la memoria interna de su dispositivo.
                </p>
                <p>
                    Este Servicio no utiliza estas "cookies" de forma explícita. Sin embargo, la aplicación puede usar código y bibliotecas de terceros que usan "cookies" para recopilar información y mejorar sus servicios. Tiene la opción de aceptar o rechazar estas cookies y saber cuándo se envía una cookie a su dispositivo. Si elige rechazar nuestras cookies, es posible que no pueda utilizar algunas partes de este Servicio.
                </p> 
                <p><div className='HTwo'>Proveedores de servicio</div></p>
                <p>
                Es posible que empleemos a empresas e individuos de terceros debido a las siguientes razones:
                </p>
                <ul className='Ul'>
                    <li>Para facilitar nuestro Servicio;</li>
                    <li>Para proporcionar el Servicio en nuestro nombre;</li>
                    <li>Para realizar servicios relacionados con el Servicio; o</li>
                    <li>Para ayudarnos a analizar cómo se utiliza nuestro Servicio.</li>
                </ul>
                <p>
                Queremos informar a los usuarios de este Servicio que estos terceros tienen acceso a su Información personal. El motivo es realizar las tareas que se les asignan en nuestro nombre. Sin embargo, están obligados a no divulgar ni utilizar la información para ningún otro fin.
                </p>
                <p><div className='HTwo'>Seguridad</div></p>
                <p>
                Valoramos su confianza al proporcionarnos su información personal, por lo que nos esforzamos por utilizar medios comercialmente aceptables para protegerla. Pero recuerde que ningún método de transmisión por Internet, o método de almacenamiento electrónico es 100% seguro y confiable, y no podemos garantizar su seguridad absoluta.
                </p>
                <p><div className='HTwo'>Enlaces a otros sitios</div></p>
                <p>
                Este Servicio puede contener enlaces a otros sitios. Si hace clic en un enlace de un tercero, será dirigido a ese sitio. Tenga en cuenta que estos sitios externos no son operados por nosotros. Por lo tanto, le recomendamos encarecidamente que revise la Política de privacidad de estos sitios web. No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de los sitios o servicios de terceros.
                </p>
                <p><div className='HTwo'>Privacidad de los niños</div></p>
                <div><p>
                Estos Servicios no están dirigidos a personas menores de 13 años. No recopilamos a sabiendas información de identificación personal de niños menores de 13 años. En el caso de que descubramos que un niño menor de 13 años nos ha proporcionado información personal, la eliminamos inmediatamente de nuestros servidores. Si usted es padre o tutor y sabe que su hijo nos ha proporcionado información personal, comuníquese con nosotros para que podamos tomar las medidas necesarias.
                </p>
                </div>
                <p><div className='HTwo'>Cambios a esta Política de privacidad</div></p>
                <p>
                Es posible que actualicemos nuestra Política de privacidad de vez en cuando. Por lo tanto, se le recomienda revisar esta página periódicamente para ver si hay cambios. Le notificaremos cualquier cambio publicando la nueva Política de privacidad en esta página.
                </p>
                <p>Esta política es efectiva a partir del 2023-06-29</p>
                <p><div className='HTwo'>Contáctenos</div></p>
                <p>
                Si tienes alguna duda o sugerencia sobre nuestra Política de Privacidad, no dudes en contactar con nosotros en <span> sistemas@holatractor.com. </span>
                </p>

            </div>

        </div>

        // </div>

        // </div>
    )
}

export default PrivacyPolicy

{/* <strong>Privacy Policy</strong> <p>
                Holatractor built the Holatractor app as
                a Free app. This SERVICE is provided by
                Holatractor at no cost and is intended for use as
                is.
            </p> <p>
                This page is used to inform visitors regarding our
                policies with the collection, use, and disclosure of Personal
                Information if anyone decided to use our Service.
            </p> <p>
                If you choose to use our Service, then you agree to
                the collection and use of information in relation to this
                policy. The Personal Information that we collect is
                used for providing and improving the Service. We will not use or share your information with
                anyone except as described in this Privacy Policy.
            </p> <p>
                The terms used in this Privacy Policy have the same meanings
                as in our Terms and Conditions, which are accessible at
                Holatractor unless otherwise defined in this Privacy Policy.
            </p> <p><strong>Information Collection and Use</strong></p> <p>
                For a better experience, while using our Service, we
                may require you to provide us with certain personally
                identifiable information. The information that
                we request will be retained by us and used as described in this privacy policy.
            </p> <div><p>
                The app does use third-party services that may collect
                information used to identify you.
            </p> <p>
                    Link to the privacy policy of third-party service providers used
                    by the app */}
{/* </p> <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li><!----><li><a href="https://firebase.google.com/policies/analytics" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li><!----><li><a href="https://www.facebook.com/about/privacy/update/printable" target="_blank" rel="noopener noreferrer">Facebook</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p><strong>Log Data</strong></p> <p> */ }
{/* We want to inform you that whenever you
                    use our Service, in a case of an error in the app
                    we collect data and information (through third-party
                    products) on your phone called Log Data. This Log Data may
                    include information such as your device Internet Protocol
                    (“IP”) address, device name, operating system version, the
                    configuration of the app when utilizing our Service,
                    the time and date of your use of the Service, and other
                    statistics.
                </p> <p><strong>Cookies</strong></p> <p>
                    Cookies are files with a small amount of data that are
                    commonly used as anonymous unique identifiers. These are sent
                    to your browser from the websites that you visit and are
                    stored on your device's internal memory.
                </p> <p>
                    This Service does not use these “cookies” explicitly. However,
                    the app may use third-party code and libraries that use
                    “cookies” to collect information and improve their services.
                    You have the option to either accept or refuse these cookies
                    and know when a cookie is being sent to your device. If you
                    choose to refuse our cookies, you may not be able to use some
                    portions of this Service.
                </p> <p><strong>Service Providers</strong></p> <p>
                    We may employ third-party companies and
                    individuals due to the following reasons:
                </p> <ul><li>To facilitate our Service;</li> <li>To provide the Service on our behalf;</li> <li>To perform Service-related services; or</li> <li>To assist us in analyzing how our Service is used.</li></ul> <p>
                    We want to inform users of this Service
                    that these third parties have access to their Personal
                    Information. The reason is to perform the tasks assigned to
                    them on our behalf. However, they are obligated not to
                    disclose or use the information for any other purpose.
                </p> <p><strong>Security</strong></p> <p>
                    We value your trust in providing us your
                    Personal Information, thus we are striving to use commercially
                    acceptable means of protecting it. But remember that no method
                    of transmission over the internet, or method of electronic
                    storage is 100% secure and reliable, and we cannot
                    guarantee its absolute security.
                </p> <p><strong>Links to Other Sites</strong></p> <p>
                    This Service may contain links to other sites. If you click on
                    a third-party link, you will be directed to that site. Note
                    that these external sites are not operated by us.
                    Therefore, we strongly advise you to review the
                    Privacy Policy of these websites. We have
                    no control over and assume no responsibility for the content,
                    privacy policies, or practices of any third-party sites or
                    services.
                </p> <p><strong>Children’s Privacy</strong></p> <div><p>
                    These Services do not address anyone under the age of 13.
                    We do not knowingly collect personally
                    identifiable information from children under 13 years of age. In the case
                    we discover that a child under 13 has provided
                    us with personal information, we immediately
                    delete this from our servers. If you are a parent or guardian
                    and you are aware that your child has provided us with
                    personal information, please contact us so that
                    we will be able to do the necessary actions. */}
{/* </p></div> <!----> <p><strong>Changes to This Privacy Policy</strong></p> <p> */ }
{/* We may update our Privacy Policy from
                    time to time. Thus, you are advised to review this page
                    periodically for any changes. We will
                    notify you of any changes by posting the new Privacy Policy on
                    this page.
                </p> <p>This policy is effective as of 2023-06-29</p> <p><strong>Contact Us</strong></p> <p>
                        If you have any questions or suggestions about our
                        Privacy Policy, do not hesitate to contact us at sistemas@holatractor.com.
                    </p> <p>This privacy policy page was created at <a href="" target="_blank" rel="noopener noreferrer">privacypolicytemplate.net </a>and modified/generated by <a href="https://app-privacy-policy-generator.nisrulz.com/" target="_blank" rel="noopener noreferrer">App Privacy Policy Generator</a></p>
                </div>
            </div> */}