// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBq-6aUC68N9Iz0xgsk2uyfrtXCqriXc6g",
  authDomain: "hola-temp-app.firebaseapp.com",
  projectId: "hola-temp-app",
  storageBucket: "hola-temp-app.appspot.com",
  messagingSenderId: "1056140943697",
  appId: "1:1056140943697:web:b5a9f614c98475a8aff370"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);