import { styled } from '@mui/material';
import React, { useState } from 'react'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import QRDemo from '../../assets/qr_hola.png'


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    // backgroundColor:
    //   theme.palette.mode === 'dark'
    //     ? 'rgba(255, 255, 255, .05)'
    //     : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));



const BookingStepper = () => {

    const [activeStepper, setActiveStepper] = useState(0)

    const stepperDetails = [
        {
            heading: "Scan the QR",
            para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam itaque voluptas qui nihil pariatur odit rem explicabo deserunt tenetur commodi.",
            imageLink: "https://wallpapercave.com/wp/wp7045459.png"
        },
        {
            heading: "Select the tractor",
            para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam itaque voluptas qui nihil pariatur odit rem explicabo deserunt tenetur commodi.",
            imageLink: "https://wallpapercave.com/wp/wp13491030.jpg"
        },
        {
            heading: "Select the attachments",
            para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam itaque voluptas qui nihil pariatur odit rem explicabo deserunt tenetur commodi.",
            imageLink: "https://wallpapercave.com/wp/wp13491034.jpg"
        },
        {
            heading: "Add your location",
            para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam itaque voluptas qui nihil pariatur odit rem explicabo deserunt tenetur commodi.",
            imageLink: "https://wallpapercave.com/wp/wp13491044.jpg"
        },
        {
            heading: "Pay upfront",
            para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam itaque voluptas qui nihil pariatur odit rem explicabo deserunt tenetur commodi.",
            imageLink: "https://wallpapercave.com/wp/wp13491047.jpg"
        },
        {
            heading: "Delivery at your doorstep",
            para: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam itaque voluptas qui nihil pariatur odit rem explicabo deserunt tenetur commodi.",
            imageLink: "https://wallpapercave.com/uwp/uwp4310349.png"
        }
    ]

    return (
        <div className='BookingStepper'>

            <h3>
                Get your two wheel tractor delivered to your door step in 3 easy steps
            </h3>

            <div className="scrolling-container" style={{scrollbarWidth: 'none'}}>

            {
                stepperDetails.map((details, index) => {
                    return (
                        <div className="container" key={index}>

                            <div className="left">

                                <div className="left-heading">
                                    {details.heading}
                                </div>

                                <div className="details">
                                    {details.para}
                                </div>

                            </div>

                            <div className="right">
                                <img
                                    src={details.imageLink}
                                    alt="qr code to book a two wheeler tractor" />
                            </div>

                        </div>
                    )
                })
            }
                
            </div>

            {/* <div className="container">

                <div className="left">

                    <div className="left-heading">
                        Heading
                    </div>

                    <div className="details">
                        Sub Heading
                    </div>

                </div>

                <div className="right">
                    <img
                        src={QRDemo}
                        alt="qr code to book a two wheeler tractor" />
                </div>

            </div> */}

        </div>
    )
}

export default BookingStepper