import React from 'react'
import { useSelector } from 'react-redux'

const PMMP = () => {

    const languageOption = useSelector(state => state.language.language)

    return (
        <div className='w-full'>

            <div className='w-[90%] 900px:w-[80%] mx-auto flex flex-col items-center justify-center gap-[30px] py-[40px]'>

                <p className='text-[#4D4D4D] text-[18px] 500px:text-[24px] 1100px:text-[27px] 1400px:text-[28px] 1500px:text-[30px]'>
                    {
                        languageOption === 'English' ?
                            'HolaTractors play a crucial role in various regenerative farming practices, including minimal tillage, cover cropping, and agroforestry.'
                            :
                            'Los HolaTractors desempeñan un papel crucial en diversas prácticas agrícolas regenerativas, incluida la labranza mínima, los cultivos de cobertura y la agrosilvicultura.'
                    }
                </p>
                {
                    languageOption === 'English' ?

                        <div
                            className='font-bold text-[18px] 500px:text-[24px] 1100px:text-[27px] 1400px:text-[28px] 1500px:text-[30px] text-[#4D4D4D] w-[90%] 600px:w-[500px] relative before:absolute before:left-[-30px] before:h-full before:w-0 600px:before:w-[3px] before:rounded-full before:bg-[#00A651] my-[30px]'>

                            <span className='text-[#00A651]'>Minimal Tillage</span> HolaTractor equipped with subsoilers and strip tillage can break up compacted soil layers without inverting the soil, improving drainage with root growth and prepare narrow strips for planting while leaving the rest of the soil undisturbed, reducing erosion and preserving soil structure.


                        </div>
                        :
                        <div
                            className='font-bold text-[18px] 500px:text-[24px] 1100px:text-[27px] 1400px:text-[28px] 1500px:text-[30px] text-[#4D4D4D] w-[90%] 600px:w-[500px] relative before:absolute before:left-[-30px] before:h-full before:w-0 600px:before:w-[3px] before:rounded-full before:bg-[#00A651] my-[30px]'>

                            <span className='text-[#00A651]'>Labranza mínima HolaTractor</span> equipado con subsoladores y labranza en franjas puede romper las capas de suelo compactadas sin invertir el suelo, mejorar el drenaje con el crecimiento de las raíces y preparar franjas estrechas para plantar mientras deja el resto del suelo sin alterar, reduciendo la erosión y preservando la estructura del suelo.


                        </div>
                }

                {
                    languageOption === 'English' ?
                        <div
                            className='font-bold text-[18px] 500px:text-[24px] 1100px:text-[27px] 1400px:text-[28px] 1500px:text-[30px] text-[#4D4D4D] w-[90%] 600px:w-[500px] relative before:absolute before:left-[-30px] before:h-full before:w-0 600px:before:w-[3px] before:rounded-full before:bg-[#00A651] my-[30px]'>

                            <span className='text-[#00A651]'>Cover Cropping:</span> Medium-sized tractors with adequate horsepower are commonly used for cover cropping. They need to be versatile for various attachments.Tractor attachments include no-till drills, broadcast seeders, and precision planters, which help plant cover crop seeds efficiently.


                        </div>
                        :
                        <div
                            className='font-bold text-[18px] 500px:text-[24px] 1100px:text-[27px] 1400px:text-[28px] 1500px:text-[30px] text-[#4D4D4D] w-[90%] 600px:w-[500px] relative before:absolute before:left-[-30px] before:h-full before:w-0 600px:before:w-[3px] before:rounded-full before:bg-[#00A651] my-[30px]'>

                            <span className='text-[#00A651]'>Cultivos de cobertura:</span> Los tractores de tamaño mediano con potencia adecuada se utilizan comúnmente para cultivos de cobertura. Deben ser versátiles para varios accesorios. Los accesorios para tractores incluyen sembradoras sin labranza, sembradoras al voleo y sembradoras de precisión, que ayudan a plantar semillas de cultivos de cobertura de manera eficiente.


                        </div>
                }

                {/* <img 
                src="https://absi.global/wp-content/uploads/2020/08/iot-monitoring-info.svg" 
                alt=""
                className='w-full' /> */}

            </div>

        </div>
    )
}

export default PMMP