import { createSlice } from '@reduxjs/toolkit'

export const languageSlice = createSlice({
    name:"language",
    initialState:{
        language : "Spanish",
    },
    reducers:{
        update: (state,action)=>{
            state.language = action.payload.languageOption;
        }
    }
})

export const { update } = languageSlice.actions;
export default languageSlice.reducer