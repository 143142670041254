import React from 'react'

const ThirdComponent = () => {

    const processBoxArray = [
        {
            id: 1,
            circleValue: 1,
            boxHeading: 'Verificación de identidad',

        },
        {
            id: 2,
            circleValue: 2,
            boxHeading: 'Documentación del vehículo',
            // paraText: 'Lorem ipsum dolor sit amet.'
        },
        {
            id: 3,
            circleValue: 3,
            boxHeading: 'Verificación de dirección',
            // paraText: 'Lorem ipsum dolor sit amet.'
        },
        {
            id: 4,
            circleValue: 4,
            boxHeading: 'Cuenta bancaria',
            // paraText: 'Lorem ipsum dolor sit amet.'
        },
    ]

    return (
        <div className='ThirdComponent'>

            <div className="heading">Nuestro proceso</div>

            <div className="processBox">

                {
                    processBoxArray.map((box) => {
                        return (
                            <div key={box.id} className='box'>

                                <div className="circe">
                                    <span>{box.circleValue}</span>
                                </div>

                                <div className="box-heading">
                                    {box.boxHeading}
                                </div>

                                <div className="para">
                                    {box.paraText}
                                </div>

                            </div>
                        )
                    })
                }

            </div>

            <div className="heading"></div>

            <div className="feedBackBox">

                <div className="left">

                    <div className="image">

                        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/tractor_owner.jpeg" alt="" />

                    </div>

                    <div className="name">Mr John D</div>

                    <div className="address">Dueño del tractor</div>
                </div>

                <div className="right">

                    <p>
                        "El proceso de incorporación a Holatractor fue increíblemente sencillo. Incluir mi tractor fue muy fácil: su plataforma en línea es fácil de usar e intuitiva. En cuestión de minutos, tenía mi tractor en la lista con descripciones claras, fotografías y mis tarifas de alquiler preferidas."
                    </p>

                    <div className='mobile-details'>

                        <div className="basic-details">

                            <div className="name">Mr John D</div>

                            <div className="address">Dueño del tractor</div>

                        </div>

                        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/tractor_owner.jpeg" alt="" />

                    </div>

                </div>

            </div>

        </div>
    )
}

export default ThirdComponent