import React from 'react'

const BannerComponent = () => {
  return (
    <div className='BannerComponent'>
        <div className='leftContainer'>
        <div className='shadow' />
            <div className='textContainer'>
                <p className='subHeading'>
                ¡Cómo la tecnología está empoderando a los agricultores!
                </p>
                <p className='mainHeading'>
                 Comunidad Holatractor
                </p>
                <p className='paragraphOne'>
                Nos apasiona apoyar a la comunidad agrícola. Creemos que la tecnología juega un papel crucial a la hora de empoderar a los pequeños agricultores para que logren una mayor eficiencia, productividad y sostenibilidad.
                </p>

                {/* <div
                className='emailGetStartedComponent'>
                    <input 
                    type="text"
                    placeholder='your company email'/>
                    <button
                    name='Get started button'>
                        Get started
                    </button>
                </div> */}
            </div>
        </div>
        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/tractor_tech_6.webp" alt="banner image" className='rightContainer' />
    </div>
  )
}

export default BannerComponent