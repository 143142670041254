import React from 'react';
import "./properties.scss";
import { useNavigate } from 'react-router-dom';

const ShowProperties = ({ image, period }) => {
  const navigate = useNavigate()
  return (
        <div className="properties-section">
            <img src={image} alt="Room One" onClick={() => navigate("/search/123")}/>
            <div className="title"> <h4> Holatractor Services</h4></div>
            <div className="para"></div>
            <div className="price">$300.00/{period}</div>
        </div>
  )
}

export default ShowProperties