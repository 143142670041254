import React from "react";
import "./creativity.scss";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FlipCameraAndroidOutlinedIcon from "@mui/icons-material/FlipCameraAndroidOutlined";
import CreativityImg from "./../../assets/g3.jpeg";

const Creativity = () => {
  const bottomIconStyle = {
    padding: "10px",
    width: "45px",
    height: "45px",
    borderRadius: "5px",
    backgroundColor: "#d0e8fd",
    color:"#469af5",
    cursor: "pointer",
  };
  return (
    <div className="creativity-section">
      <h2>
      ¿Cómo podemos ayudar con<span> HolaTractor!</span>
      </h2>
      <p>
        {/* Whether you’re looking for designs or photographs, you'll find the
        perfect asset on Freepik. */}
      </p>
      <div className="creativity-container">
        <div className="creativity-left">
          <div className="d-flex">
            <StarBorderOutlinedIcon style={bottomIconStyle}/>
            <div className="text">
              <h3>Servicios de tractores</h3>
              <p>
              Brindamos el mejor servicio y transporte de tractores en toda el área tri-estatal. Nuestro servicio óptimo está a su conveniencia, con un desempeño rápido a un precio razonable.
              </p>
            </div>
          </div>

          <div className="d-flex">
            <ThumbUpOutlinedIcon style={bottomIconStyle} />
            <div className="text">
              <h3>Asistencia financiera para propietarios de tractores</h3>
              <p>
              Si desea comprar un tractor, lo financiaremos por usted y lo ayudaremos a ganar dinero a través de nuestros servicios de tractores.
              </p>
            </div>
          </div>

          <div className="d-flex">
            <SearchOutlinedIcon style={bottomIconStyle}/>
            <div className="text">
              <h3>Oportunidades de afiliación para nuestros socios</h3>
              <p>
              Hacemos alianzas con personalidades de liderazgo que administran grupos comunitarios a través de nuestra plataforma inteligente. Y ofrecemos una buena oportunidad de ganar.
              </p>
            </div>
          </div>

          <div className="d-flex">
            <FlipCameraAndroidOutlinedIcon style={bottomIconStyle} />
            <div className="text">
              <h3>Grupo de la comunidad!</h3>
              <p>
              Nuestra comunidad , es un grupo de personas que viven cercanos , unos de otros, y comparten intereses comunes, como la cultura o la historia. En muchos casos, las comunidades agrícolas son estrechas, y se apoyan mutuamente, lo que puede ser muy beneficioso cuando se trata de productividad agrícola o iniciar un nuevo negocio.
              </p>
            </div>
          </div>
        </div>
        <div className="creativity-right">
          <img src={CreativityImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Creativity;
