import React from 'react'
import { useSelector } from 'react-redux'

const RegenerativeAgricultureFive = () => {
    const languageOption = useSelector(state => state.language.language)
    const imageArray = [
        "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/small_tractor.jpg",
        "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/meets.jpeg",
        "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/consulting.jpeg",
        "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/palm_collect.jpeg",
        "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/palm_collect1.jpeg"
    ]
  return (
    <div className='w-[90%] 1000px:w-[80%] mx-auto'>
        <div className='w-full flex items-center gap-[20px] relative pb-[40px] flex-col 1000px:flex-row'>
            <img 
            src={imageArray[0]} 
            alt="tractor" 
            className='w-full 1000px:w-1/2 h-auto object-cover'
             />
             <div className='w-full 1000px:w-1/2'>
                <p className='text-[26px] font-bold 900px:text-[42px] 500px:text-[36px]'>
                    {
                        languageOption === 'English' ?
                        'Heading in English'
                        :
                        'Heading in spanish'
                    }
                </p>
                <div className='mt-[30px] flex flex-col gap-[20px]'>
                <div>
                <p className='font-bold text-[22px]'>
                    {
                        languageOption === 'English' ?
                        'Point two in english'
                        :
                        'Point two in spanish'
                    }
                </p>
                <p>
                {
                        languageOption === 'English' ?
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in english'
                        :
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in spanish'
                    }
                </p>
                </div>
                <div>
                <p className='font-bold text-[22px]'>
                    {
                        languageOption === 'English' ?
                        'Point two in english'
                        :
                        'Point two in spanish'
                    }
                </p>
                <p>
                {
                        languageOption === 'English' ?
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in english'
                        :
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in spanish'
                    }
                </p>
                </div>
                <div>
                <p className='font-bold text-[22px]'>
                    {
                        languageOption === 'English' ?
                        'Point two in english'
                        :
                        'Point two in spanish'
                    }
                </p>
                <p>
                {
                        languageOption === 'English' ?
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in english'
                        :
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in spanish'
                    }
                </p>
                </div>
                </div>
             </div>
             <div className='w-[200px] h-[4px] rounded-full bg-green-600 absolute bottom-[20px] left-1/2 -translate-x-1/2' />
        </div>
        <div className='w-full flex flex-col 1000px:flex-row-reverse items-center gap-[20px] relative pb-[40px]'>
            <img 
            src={imageArray[1]} 
            alt="tractor" 
            className='w-full 1000px:w-1/2 h-auto object-cover'
             />
             <div className='w-full 1000px:w-1/2'>
                <p className='text-[26px] font-bold 900px:text-[42px] 500px:text-[36px]'>
                    {
                        languageOption === 'English' ?
                        'Heading in English'
                        :
                        'Heading in spanish'
                    }
                </p>
                <div className='mt-[30px] flex flex-col gap-[20px]'>
                <div>
                <p className='font-bold text-[22px]'>
                    {
                        languageOption === 'English' ?
                        'Point two in english'
                        :
                        'Point two in spanish'
                    }
                </p>
                <p>
                {
                        languageOption === 'English' ?
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in english'
                        :
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in spanish'
                    }
                </p>
                </div>
                <div>
                <p className='font-bold text-[22px]'>
                    {
                        languageOption === 'English' ?
                        'Point two in english'
                        :
                        'Point two in spanish'
                    }
                </p>
                <p>
                {
                        languageOption === 'English' ?
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in english'
                        :
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in spanish'
                    }
                </p>
                </div>
                <div>
                <p className='font-bold text-[22px]'>
                    {
                        languageOption === 'English' ?
                        'Point two in english'
                        :
                        'Point two in spanish'
                    }
                </p>
                <p>
                {
                        languageOption === 'English' ?
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in english'
                        :
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in spanish'
                    }
                </p>
                </div>
                </div>
             </div>
             <div className='w-[200px] h-[4px] rounded-full bg-green-600 absolute bottom-[20px] left-1/2 -translate-x-1/2' />
        </div>
        <div className='w-full flex items-center gap-[20px] relative pb-[40px] flex-col 1000px:flex-row'>
            <img 
            src={imageArray[2]} 
            alt="tractor" 
            className='w-full 1000px:w-1/2 h-auto object-cover'
             />
             <div className='w-full 1000px:w-1/2'>
                <p className='text-[26px] font-bold 900px:text-[42px] 500px:text-[36px]'>
                    {
                        languageOption === 'English' ?
                        'Heading in English'
                        :
                        'Heading in spanish'
                    }
                </p>
                <div className='mt-[30px] flex flex-col gap-[20px]'>
                <div>
                <p className='font-bold text-[22px]'>
                    {
                        languageOption === 'English' ?
                        'Point two in english'
                        :
                        'Point two in spanish'
                    }
                </p>
                <p>
                {
                        languageOption === 'English' ?
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in english'
                        :
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in spanish'
                    }
                </p>
                </div>
                <div>
                <p className='font-bold text-[22px]'>
                    {
                        languageOption === 'English' ?
                        'Point two in english'
                        :
                        'Point two in spanish'
                    }
                </p>
                <p>
                {
                        languageOption === 'English' ?
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in english'
                        :
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in spanish'
                    }
                </p>
                </div>
                <div>
                <p className='font-bold text-[22px]'>
                    {
                        languageOption === 'English' ?
                        'Point two in english'
                        :
                        'Point two in spanish'
                    }
                </p>
                <p>
                {
                        languageOption === 'English' ?
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in english'
                        :
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in spanish'
                    }
                </p>
                </div>
                </div>
             </div>
             <div className='w-[200px] h-[4px] rounded-full bg-green-600 absolute bottom-[20px] left-1/2 -translate-x-1/2' />
        </div>
        <div className='w-full flex items-center flex-col 1000px:flex-row-reverse gap-[20px] relative pb-[40px]'>
            <img 
            src={imageArray[3]} 
            alt="tractor" 
            className='w-full 1000px:w-1/2 h-auto object-cover'
             />
             <div className='w-full 1000px:w-1/2'>
                <p className='text-[26px] font-bold 900px:text-[42px] 500px:text-[36px]'>
                    {
                        languageOption === 'English' ?
                        'Heading in English'
                        :
                        'Heading in spanish'
                    }
                </p>
                <div className='mt-[30px] flex flex-col gap-[20px]'>
                <div>
                <p className='font-bold text-[22px]'>
                    {
                        languageOption === 'English' ?
                        'Point two in english'
                        :
                        'Point two in spanish'
                    }
                </p>
                <p>
                {
                        languageOption === 'English' ?
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in english'
                        :
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in spanish'
                    }
                </p>
                </div>
                <div>
                <p className='font-bold text-[22px]'>
                    {
                        languageOption === 'English' ?
                        'Point two in english'
                        :
                        'Point two in spanish'
                    }
                </p>
                <p>
                {
                        languageOption === 'English' ?
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in english'
                        :
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in spanish'
                    }
                </p>
                </div>
                <div>
                <p className='font-bold text-[22px]'>
                    {
                        languageOption === 'English' ?
                        'Point two in english'
                        :
                        'Point two in spanish'
                    }
                </p>
                <p>
                {
                        languageOption === 'English' ?
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in english'
                        :
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in spanish'
                    }
                </p>
                </div>
                </div>
             </div>
             <div className='w-[200px] h-[4px] rounded-full bg-green-600 absolute bottom-[20px] left-1/2 -translate-x-1/2' />
        </div>
        <div className='w-full flex items-center flex-col 1000px:flex-row gap-[20px] relative pb-[40px]'>
            <img 
            src={imageArray[4]} 
            alt="tractor" 
            className='w-full 1000px:w-1/2 h-auto object-cover'
             />
             <div className='w-full 1000px:w-1/2'>
                <p className='text-[26px] font-bold 900px:text-[42px] 500px:text-[36px]'>
                    {
                        languageOption === 'English' ?
                        'Heading in English'
                        :
                        'Heading in spanish'
                    }
                </p>
                <div className='mt-[30px] flex flex-col gap-[20px]'>
                <div>
                <p className='font-bold text-[22px]'>
                    {
                        languageOption === 'English' ?
                        'Point two in english'
                        :
                        'Point two in spanish'
                    }
                </p>
                <p>
                {
                        languageOption === 'English' ?
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in english'
                        :
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in spanish'
                    }
                </p>
                </div>
                <div>
                <p className='font-bold text-[22px]'>
                    {
                        languageOption === 'English' ?
                        'Point two in english'
                        :
                        'Point two in spanish'
                    }
                </p>
                <p>
                {
                        languageOption === 'English' ?
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in english'
                        :
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in spanish'
                    }
                </p>
                </div>
                <div>
                <p className='font-bold text-[22px]'>
                    {
                        languageOption === 'English' ?
                        'Point two in english'
                        :
                        'Point two in spanish'
                    }
                </p>
                <p>
                {
                        languageOption === 'English' ?
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in english'
                        :
                        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quasi. in spanish'
                    }
                </p>
                </div>
                </div>
             </div>
             <div className='w-[200px] h-[4px] rounded-full bg-green-600 absolute bottom-[20px] left-1/2 -translate-x-1/2' />
        </div>
    </div>
  )
}

export default RegenerativeAgricultureFive