import React from 'react'
import Navbar from '../../palmComponents/Navbar/Navbar'
import PricingComponent from '../../palmComponents/PricingComponent/PricingComponent'
import Footer from '../../palmComponents/Footer/Footer'

const Pricing = () => {
  
  return (
    <div
    className='w-full min-h-[100vh] max-h-fit pt-[13vh] bg-gray-100'>

      <Navbar />

      <PricingComponent />

      <Footer />

    </div>
  )
}

export default Pricing