import React from "react";
import "./appdownload.scss"
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import PlayStore from "../../assets/play-store-logo-33874.png"
import IosStore from "../../assets/pngwing.com.png";
import PhoneImg from "../../assets/phone.png";

const AppDownload = () => {
  return (
    <div className="app-main">
      <div className="app-download">
        <div className="left-container">
          <h2>
            Descarga la aplicación móvil Holatractor
            <span>Servicios de tractores en una solución integral</span>
          </h2>
          <div className="line-one">
            <CheckOutlinedIcon />
            <h3>Gane más uniéndose a nuestro programa de propietarios de tractores</h3>
          </div>
          <div className="line-two">
            <CheckOutlinedIcon />
            <h3>Mantén tu finca agrícola con holatractor</h3>
          </div>
          <div className="get-app">
            <a href="https://play.google.com/store/apps/details?id=com.nims.holatractor">
              <img src={PlayStore} alt="" />
            </a>
            {/* <img src={IosStore} alt="" /> */}
          </div>
        </div>
        <div className="right-container">
          <img src={PhoneImg} alt="" />

        </div>
      </div>
    </div>
  );
};

export default AppDownload;
