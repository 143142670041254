import React, { useRef } from 'react'
import './VehicleInformation.scss'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const VehicleInformation = () => {
    let scrl = useRef(null);

    const vehicleInformationArray = [
        {
            imgUrl: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/serviso/cropcollect_tractor.webp"
        },
        {
            imgUrl: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/serviso/farming_tractor.webp"
        },
        {
            imgUrl: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/serviso/grasscutter_tractor.webp"
        },
        {
            imgUrl: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/serviso/land_preparation.webp"
        },
        {
            imgUrl: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/serviso/lawncare_tractorr.webp"
        },
        {
            imgUrl: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/serviso/medium_tractor.webp"
        },
        {
            imgUrl: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/serviso/mini_tractor.webp"
        },
        {
            imgUrl: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/serviso/preparing_land_tractor.webp"
        },
        {
            imgUrl: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/serviso/seeding.webp"
        },
        {
            imgUrl: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/serviso/spray_tractor.webp"
        },
    ]

    const slide = (shift) => {
        scrl.current.scrollLeft += shift;
      };

    return (
        <div className='VehicleInformation'>

            <div className="container">

                <div className="headings">
                    <p>Aumente su productividad</p>
                    <p>Crecer con confianza</p>
                </div>

                <div className='below-headings-section'>

                    <p className="sub-heading">
                    Tractores para granjas de todos los tamaños
                    </p>

                    <div className="scrolling-button-section">

                        <div className="Button" onClick={()=>{slide(-150)}}>
                        <i className="fa-solid fa-arrow-left"></i>
                        </div>

                        <div className="Button" onClick={()=>{slide(+150)}}>
                        <i className="fa-solid fa-arrow-right"></i>
                        </div>

                    </div>

                </div>

                <div className="image-container" style={{ scrollbarWidth: 'none' }} ref={scrl}>

                    {
                        vehicleInformationArray.map((details, index) => {
                            return (
                                <div className="image-box" key={index}>

                                    <img
                                        src={details.imgUrl}
                                        alt="vehicle image"
                                        className='image-section' />

                                    <div className='link-button'>
                                        <p>
                                            Know more
                                        </p>
                                        <ArrowRightAltIcon />
                                    </div>

                                </div>
                            )
                        })
                    }

                </div>

            </div>

        </div>
    )
}

export default VehicleInformation