import React from 'react'
import Navbar from '../../palmComponents/Navbar/Navbar'
import PalmRecollectionOne from '../../palmComponents/PalmRecollection/PalmRecollectionOne'
import PalmRecollectionTwo from '../../palmComponents/PalmRecollection/PalmRecollectionTwo'
import PalmRecollectionThree from '../../palmComponents/PalmRecollection/PalmRecollectionThree'
import PalmRecollectionFour from '../../palmComponents/PalmRecollection/PalmRecollectionFour'
import PalmRecollectionSix from '../../palmComponents/PalmRecollection/PalmRecollectionSix'
import PalmRecollectionFive from '../../palmComponents/PalmRecollection/PalmRecollectionFive'
import PalmRecollectionSeven from '../../palmComponents/PalmRecollection/PalmRecollectionSeven'
import Footer from '../../palmComponents/Footer/Footer'

const PalmRecollection = () => {
  return (
    <div className='w-full'>

      <Navbar />

      <PalmRecollectionOne />

      <PalmRecollectionTwo />

      <PalmRecollectionThree />

      <PalmRecollectionFour />

      <PalmRecollectionFive />

      <PalmRecollectionSix />

      <PalmRecollectionSeven />

      <Footer />

    </div>
  )
}

export default PalmRecollection