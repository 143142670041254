import React from 'react';
import "./showProperties.scss"
import Properties from "./Properties/Properties"
import RoomOne from "./../../assets/g2.jpeg"
import RoomTwo from "./../../assets/service1.jpg"
import RoomThree from "./../../assets/service2.jpg"
import RoomFour from "./../../assets/service3.jpg"
import RoomFive from "./../../assets/service4.jpg"
import Room1 from "./../../assets/service5.jpg"
import Room2 from "./../../assets/service6.jpg"
import Room3 from "./../../assets/service7.jpg"
import Room4 from "./../../assets/service8.jpg"
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ApprovedIcon from './approved.png'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PushPinIcon from '@mui/icons-material/PushPin';
import MapIcon from '@mui/icons-material/Map';
import StreetviewIcon from '@mui/icons-material/Streetview';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';

const ShowProperties = () => {
  return (
    <div className='properties-container'>
      {/* <Properties image={RoomFive} />
      <Properties image={RoomFour} />
      <Properties image={RoomThree} />
      <Properties image={RoomTwo} />
      <Properties image={RoomOne} />
      <Properties image={RoomFour} />
      <Properties image={Room1} />
      <Properties image={Room2} />
      <Properties image={Room3} />
      <Properties image={Room4} />
      <Properties image={RoomThree} />
      <Properties image={RoomTwo} /> */}

      <div className="container">

        <div className="box">

          <div className="box-left">

            <p className='verified verified-bg'>
              Verified
            </p>

            <div className='profile-bottom-section'>

              <div className="top-section">

                <div className="top-left-section">

                  <img src="https://wallpapercave.com/wp/wp13661007.png" alt="" className='profile' />

                  <img src={ApprovedIcon} alt="" className='profile-badge' />

                </div>

                <div className="top-right-section">

                  <div className="deale-rname">
                    Owner name
                  </div>

                  <div className="total-services">
                    500+ bookings
                  </div>

                </div>

              </div>

            </div>

            <img src={Room1} alt="Room one image" />

          </div>

          <div className="box-right">

            <div className="box-right-top">

              <div className="box-right-top-left">

                <div className="store-name">
                  Store name
                </div>

                <div className="rent-price">
                  Rent price
                </div>

              </div>

              <div className="box-right-top-right">
                <FavoriteBorderIcon />
              </div>

            </div>

            <div className="paragraphs-container">

              <p className="mainHeading">
                Urbana new towers
              </p>

              <p className="middleHeading">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam, sit.
              </p>

              <div className="thirdHeading">
                <span>Lorem, ipsum dolor.</span> Lorem ipsum dolor sit amet.
              </div>

            </div>

            <div className="possesion-section">

              <div className="box-possesion">

                <MapIcon />

                <div className="location">

                  <div className="location-heading">
                    Location
                  </div>

                  <div className="location-value">
                    Bolivio
                  </div>

                </div>

              </div>

              <div className="box-possesion">

                <ThumbsUpDownIcon />

                <div className="location">

                  <div className="location-heading">
                    Rating
                  </div>

                  <div className="location-value">
                    3.2/5.0
                  </div>

                </div>

              </div>

              <div className="box-possesion">

                <StreetviewIcon />

                <div className="location">

                  <div className="location-heading">
                    Distance
                  </div>

                  <div className="location-value">
                    20 miles
                  </div>

                </div>

              </div>

            </div>

            <div className="distance-section">

              <div className="left-most">
                <LocationOnIcon /> Distance:
              </div>

              <div className="sliding-section">

                <div className="slide">
                  <PushPinIcon /> John deere <span>1.7km</span>
                </div>

                <div className="slide">
                  <PushPinIcon /> Sonalika <span>1.7km</span>
                </div>

              </div>

            </div>

            <div className="buttom-section">

              <div className="bottom-section-left">

                <div className="left-most">
                  S
                </div>

                <div className="beside-left">

                  <div className="long-heading">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nobis, magni!
                  </div>

                  <div className="short-heading">
                    Developer +2 more
                  </div>

                </div>

              </div>

              <div className="buttons-section">

                <button
                  name='take a look button'
                  className='button1'>
                  Take a look
                </button>

                <button
                  name='view now button'
                  className='button2'>
                  View now
                </button>

              </div>

            </div>

          </div>

        </div>

      </div>

    </div>
  )
}

export default ShowProperties