import React from 'react'
import { useSelector } from 'react-redux'

const PalmMills = () => {

    const languageOption = useSelector(state => state.language.language)

    const array = languageOption === 'English' ? [
        {
            heading: "Soil Health Monitoring",
            listArray: [
                "Measure soil moisture levels to optimize irrigation and prevent overwatering.Monitor soil temperature to assess its impact on plant growth and microbial activity.Measure nutrient levels in the soil, enabling precise fertilization and preventing nutrient runoff."
            ]
        },
        {
            heading: "Crop Monitoring",
            listArray: [
                "Device sensors to detect diseases, pests, and nutrient deficiencies in crops, allowing for timely interventions. Monitor weather conditions, including temperature, humidity, wind speed, and rainfall, to make data-driven farming decisions.Measure leaf wetness to predict and prevent fungal diseases."
            ]
        },
        {
            heading: "Precision Irrigation",
            listArray: [
                "Device sensors to detect soil moisture levels and automate irrigation systems, ensuring optimal water use. Regulate water flow and distribute water precisely to conserve resources."
            ]
        },
        {
            heading: "Data Analytics and Predictive Tools",
            listArray: [
                "Analyze data from sensors to provide insights into crop health, soil quality, and weather patterns, enabling predictive analytics.Which recommendations to farmers based on collected data, helping them make informed decisions about planting, irrigation, and pest control.",
                
            ]
        },
    ]

    :

    [
        {
            heading: "Salud del suelo",
            listArray: [
                "Mida los niveles de humedad del suelo para optimizar el riego y evitar el exceso de agua. Supervise la temperatura del suelo para evaluar su impacto en el crecimiento de las plantas y la actividad microbiana. Mida los niveles de nutrientes en el suelo, permitiendo una fertilización precisa y evitando la escorrentía de nutrientes."
            ]
        },
        {
            heading: "Monitoreo de cultivos",
            listArray: [
                "Dispositivos sensores para detectar enfermedades, plagas y deficiencias de nutrientes en los cultivos, permitiendo intervenciones oportunas. Supervise las condiciones climáticas, incluida la temperatura, la humedad, la velocidad del viento y las precipitaciones, para tomar decisiones agrícolas basadas en datos. Mida la humedad de las hojas para predecir y prevenir enfermedades fúngicas."
            ]
        },
        {
            heading: "Riego de Precisión",
            listArray: [
                "Dispositivos sensores para detectar niveles de humedad del suelo y automatizar sistemas de riego, asegurando un uso óptimo del agua. Regule el flujo de agua y distribuya el agua con precisión para conservar los recursos."
            ]
        },
        {
            heading: "Análisis de datos",
            listArray: [
                `Analice los datos de los sensores para proporcionar información sobre la salud de los cultivos, la calidad del suelo y los patrones climáticos, lo que permite realizar análisis predictivos. Ofrece recomendaciones a los agricultores basadas en los datos recopilados, ayudándolos a tomar decisiones informadas sobre la siembra, el riego y el control de plagas.`
            ]
        },
    ]

    return (
        <div className='w-full py-[40px]'>

            <div className='w-[90%] 900px:w-[80%] mx-auto flex items-center justify-center flex-col'>

                <p className='text-[30px] 500px:text-[38px] 800px:text-[40px] 1500px:text-[42px] text-[#4D4D4D] font-bold mb-[30px] text-center'>
                    {
                        languageOption === 'English' ?
                        'Integrating our IoT (Internet of Things) devices into regenerative agriculture practices can significantly enhance efficiency, sustainability, and overall farm management.'
                        :
                        'La integración de nuestros dispositivos IoT (Internet de las cosas) en prácticas de agricultura regenerativa puede mejorar significativamente la eficiencia, la sostenibilidad y la gestión agrícola general.' 
                    }
                </p>

                <img
                    src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/palm/regenagri.jfif"
                    alt=""
                    className='w-full mb-[20px] rounded-[20px]' />

                <p className='text-[#4D4D4D] text-[18px]'>
                    ..............
                    {
                        languageOption === 'English' ?
                        ''
                        :
                        ''
                    }
                </p>

                <div className='w-full h-[1px] bg-gray-300 my-[26px]' />

                <div className='w-full grid grid-cols-1 900px:grid-cols-2'>

                    {/* <div className='p-[20px]'>

                        <p className='text-[22px] font-[600] text-[#028A86]'>
                            FUNCTION
                        </p>

                            <ul className='list-disc pl-[20px] mt-[16px]'>

                                <li>

                                    <p className='text-[18px] text-[#4D4D4D]'>
                                        Digestion is the process of releasing the palm oil in the loose fruit through the rupture or breaking down of the oil-bearing cells. The temperature of the digester is critical and has to be maintained between a preset temperature range to reduce the viscosity of the crude oil in order to facilitate oil removal at the bottom of the digester and to improve the next pressing operation.
                                    </p>
                                </li>

                            </ul>

                    </div> */}

                    {
                        array.map((list, index) => {
                            return (
                                <div className='p-[20px]' key={index}>

                                    <p className='text-[20px] 500px:text-[21px] 1100px:text-[22px] 1500px:text-[23px] font-[600] text-[#028A86]'>
                                        {list.heading}
                                    </p>

                                    <ul className='list-disc pl-[20px]'>

                                        {
                                            list.listArray.map((arrayItem) => {
                                                return (
                                                    <li key={arrayItem}>

                                                        <p className='text-[16px] 500px:text-[18px] 1100px:text-[19px] 1500px:text-[21px] text-[#4D4D4D] mt-[16px]'>
                                                            {arrayItem}
                                                        </p>
                                                    </li>
                                                )
                                            })
                                        }

                                    </ul>

                                </div>
                            )
                        })
                    }

                </div>

                <p className='mt-[20px] text-[18px] 500px:text-[24px] 1100px:text-[27px] 1400px:text-[28px] 1500px:text-[30px] text-[#4D4D4D]'>
               
                    {
                        languageOption === 'English' ?
                        ' Our IoT devices can empower farmers practicing regenerative agriculture to make data-driven decisions, conserve resources, enhance biodiversity, and ultimately promote sustainable and resilient farming practices.'
                        :
                        'Nuestros dispositivos de IoT pueden capacitar a los agricultores que practican la agricultura regenerativa para tomar decisiones basadas en datos, conservar recursos, mejorar la biodiversidad y, en última instancia, promover prácticas agrícolas sostenibles y resilientes.'
                    }
                </p>

            </div>

        </div>
    )
}

export default PalmMills