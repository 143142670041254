import React from 'react'
import Navbar from '../../palmComponents/Navbar/Navbar'
import Footer from '../../palmComponents/Footer/Footer'
import RegenerativeAgricultureOne from '../../palmComponents/RegenerativeAgriculture/RegenerativeAgricultureOne'
import RegenerativeAgricultureTwo from '../../palmComponents/RegenerativeAgriculture/RegenerativeAgricultureTwo'
import RegenerativeAgricultureThree from '../../palmComponents/RegenerativeAgriculture/RegenerativeAgricultureThree'
import RegenerativeAgricultureFour from '../../palmComponents/RegenerativeAgriculture/RegenerativeAgricultureFour'
import RegenerativeAgricultureFive from '../../palmComponents/RegenerativeAgriculture/RegenerativeAgricultureFive'

const RegenerativeAgriculture = () => {
  return (
    <div className="w-full relative">
      <div className="absolute h-full w-[1px] z-50 bg-yellow-200 left-[10%] top-0 hidden 900px:block" />
      <div className="absolute h-full w-[1px] z-50 bg-yellow-200 left-[50%] top-0 hidden 900px:block" />
      <div className="absolute h-full w-[1px] z-50 bg-yellow-200 right-[10%] top-0 hidden 900px:block" />
      <Navbar />
      <RegenerativeAgricultureOne />
      <RegenerativeAgricultureTwo />
      <RegenerativeAgricultureThree />
      <RegenerativeAgricultureFour />
      <RegenerativeAgricultureFive />
      <Footer />
    </div>
  )
}

export default RegenerativeAgriculture