import React from 'react'
import './ServiciosDeSeguimineto.scss'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import HowItWorksSection from './HowItWorksSection';
import ThirdComponent from './ThirdComponent';
import FourthComponent from './FourthComponent';
import PricingComponent from './PricingComponent';

const ServiciosDeSeguimineto = () => {
  return (
    <div className='ServiciosDeSeguimineto'>

        {/* <div className="top-image-component">

            <img src="https://wallpapercave.com/dwp1x/wp9772211.jpg" alt="" />

            <div className="right-top-circle" />
            <div className="right-bottom-circle" />
            <div className="left-top-circle" />
            <div className="left-bottom-circle" />
            <div className="bottom-rectangle" />
            <div className="top-rectangle" />

            <div className="text-part">
                <div className='left'>
                    <div>Partner with Quick Ride to change</div>
                    <div>the way employees get to work</div>
                </div>
                <div className="right">
                    <span>Apply now</span> <MailOutlineIcon />
                </div>
            </div>

        </div> */}

        {/* <HowItWorksSection /> */}

        {/* <ThirdComponent /> */}

        {/* <FourthComponent /> */}

        {/* <PricingComponent /> */}

        {/* Need to update content */}

    </div>
  )
}

export default ServiciosDeSeguimineto