import React from 'react'
import './BeneficioTecnológicoParaLaAgricultura.scss'
import BannerComponent from './BannerComponent'
import SecondComponent from './SecondComponent'
import ThirdComponent from './ThirdComponent'
import FourthComponent from './FourthComponent'

const BeneficioTecnológicoParaLaAgricultura = () => {
  return (
    <div
    className='mainContainer'>
      <BannerComponent />
      <SecondComponent />
      <ThirdComponent />
      <FourthComponent />
    </div>
  )
}

export default BeneficioTecnológicoParaLaAgricultura