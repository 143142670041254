import React from "react";
import "./homeMiddle.scss";
import MiddleBtn from "./MiddleButton/MiddleBtn";

const HomeMiddle = () => {
 
  return (
    <div className="home-middle">
      <div className="left-middle">
        {/* <iframe
          width="445"
          height="250"
          src="https://www.youtube.com/embed/CHeSlbm1brg"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe> */}
        <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fholatractor%2Fvideos%2F991073688753606%2F&show_text=0&width=500" width="500" height="280" style={{border:'none',overflow:'hidden'}} scrolling="no" frameBorder="0" allowtransparency="true" allowFullScreen={true}></iframe>

      </div>
      <div className="right-middle">
        <div className="heading">
          <h1>Construyamos juntos holatractor</h1>
          <h3>Brindamos solución integral en servicios de tractores</h3>
          <p>Agricultores | Dueño del tractor | Agentes | Operadores</p>
        </div>
        <div className="middle-btn">
          <MiddleBtn />
        </div>
      </div>
    </div>
  );
};

export default HomeMiddle;
