import React from 'react';
import "./forbuilders.scss";
import BuilderLogo from "../../../../utils/d_hp_ppf_xl.jpg"
import { Link } from 'react-router-dom';

const ForBuilders = () => {
    return (
        <div className='forbuilder-container'>
            <div className="builder-box-one">
                {/* <p>¿Cómo Trabajamos?</p> */}

                <p>
                    <Link
                        to={"/denu-del-tractor"}
                        style={{
                            textDecoration: "none",
                            color: 'black'
                        }}>
                        dueño del tractor
                    </Link>
                </p>

                {/* <p> Únete A La Comunidad</p> */}
                <p> tener un tractor nuevo</p>
                {/* <p> ¿Cómo Ser Parte?</p> */}
                <p> añadir servicio de tractor</p>
                <p> Gestión de flotas</p>
                <div className="box-one-bottom">
                    <p>servicios especiales</p>
                    {/* <p>Contacta con nosotros</p>
                    <span>+591 77613811</span> */}
                </div>
            </div>

            <div className="builder-box-two">
                <p>oferta del propietario</p>
                <div className="box-two-text">
                    <Link
                        to={"/service"}
                        style={{
                            textDecoration: "none"
                        }}>
                        <p>
                            Servicios de tractores
                        </p>
                    </Link>
                    <Link
                        to={"/tractor-assembly-service"}
                        style={{
                            textDecoration: "none"
                        }}>
                        <p>
                            Servicios de montaje de tractores
                        </p>
                    </Link>
                    <Link
                        to={"/servicios-de-seguimineto"}
                        style={{
                            textDecoration: "none"
                        }}>
                        <p>
                            Servicios de seguimiento de tractores
                        </p>
                    </Link>
                    <Link
                        to={"/servicios-mensuales"}
                        style={{
                            textDecoration: "none"
                        }}>
                        <p>servicios semanales o mensuales</p>
                    </Link>
                </div>
            </div>

            <div className="builder-box-three">
                <img src={BuilderLogo} alt="Builder Image" />
            </div>

            <div className="builder-box-four">

                <a href="#" target="_blank" rel="noopener noreferrer">
                    <div className="button1 button">Únete como dueño</div>
                </a>

                <a href="#" target="_blank" rel="noopener noreferrer">
                    <div className="button2 button">Acceso</div>
                </a>

            </div>

        </div>
    )
}

export default ForBuilders