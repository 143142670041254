import React from 'react';
import "./forbuilders.scss";
import BuilderLogo from "../../../../utils/d_hp_ppf_xl.jpg"

const ForInvestor = () => {
    return (
        <div className='forbuilder-container'>
            <div className="builder-box-one">
                {/* <p>¿Cómo Trabajamos?</p> */}
                <p>¿Cómo invertir con holatractor?</p>
                {/* <p> Únete A La Comunidad</p> */}
                <p>¿Quieres ser inversor a pequeña escala?</p>
                {/* <p> ¿Cómo Ser Parte?</p> */}
                <p>¿Qué es el crowdfunding de Holatractor?</p>
                <p>¿Cómo asegurar su dinero y obtener beneficios con holatractor?</p>
                {/* <div className="box-one-bottom">
                    <p>servicios especiales</p>
                    <p>Contacta con nosotros</p>
                    <span>+591 77613811</span>
                </div> */}
            </div>
            <div className="builder-box-two">
                <p>Relación con inversores</p>
                <div className="box-two-text">
                    <p>
                    Datos de inversores
                    </p>
                    <p>Documentos de Holatrctor
                    </p>
                    {/* <p>Mesa de ayuda

                    </p>
                    <p>Servicios de reparación de escalones</p> */}
                    <p>
                        <button>Aplica ya</button>
                    </p>
                </div>
            </div>
            <div className="builder-box-three">
                <img src={BuilderLogo} alt="Builder Image" />
            </div>
        </div>
    )
}

export default ForInvestor